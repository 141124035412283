import {defineMessages} from 'react-intl';
import {inject, injectable} from 'inversify';
import {Epic, ofType} from 'redux-observable';
import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf, PayloadAction} from 'typesafe-actions';

import {ServiceTypes} from '@inversify';
import {MutationUpdateUserArgs, MutationUpdateUserLicenseArgs, UserProfile} from '@models/generated/graphql';
import {map, mergeMap} from '@otel';
import {BaseEpicsBuilder} from '@redux';
import {entityActions, EntityType, UserProfileNormalized} from '@redux/entity';
import {IUserProfileService} from '@services';
import {GqlMutationRequest, ServiceResponsePayload} from '@services/types';
import {formatTimestamp} from '@utils';

import {showErrorAction, showMessageAction} from '../message-snack-bar/actions';

import {
    EditEmailRequestModel,
    EditLicenseTypeRequestPayload,
    EditPersonalInfoRequestPayload,
    EditPhoneRequestModel,
    personalInfoActions,
} from './actions';

const localized = defineMessages({
    editEmailSuccess: {
        id: 'UserProfilePersonalInfoEpicsBuilder_editEmailSuccess',
        defaultMessage: 'Email successfully changed to {email}',
    },
    editPhoneSuccess: {
        id: 'UserProfilePersonalInfoEpicsBuilder_editPhoneSuccess',
        defaultMessage: 'Phone successfully changed to {phone}',
    },
    editFirstNameSuccess: {
        id: 'UserProfilePersonalInfoEpicsBuilder_editFirstNameSuccess',
        defaultMessage: 'First Name successfully changed to {first_name}',
    },
    editLastNameSuccess: {
        id: 'UserProfilePersonalInfoEpicsBuilder_editLastNameSuccess',
        defaultMessage: 'Last Name successfully changed to {last_name}',
    },
    editNicknameSuccess: {
        id: 'UserProfilePersonalInfoEpicsBuilder_editNicknameSuccess',
        defaultMessage: 'Nickname successfully changed to {nickname}',
    },
    editGenderSuccess: {
        id: 'UserProfilePersonalInfoEpicsBuilder_editGenderSuccess',
        defaultMessage: 'Gender successfully changed to {gender}',
    },
    editDateOfBirthSuccess: {
        id: 'UserProfilePersonalInfoEpicsBuilder_editDateOfBirthSuccess',
        defaultMessage: 'Date Of Birth successfully changed to {dateOfBirth}',
    },
    editEmailFailure: {
        id: 'UserProfilePersonalInfoEpicsBuilder_editEmailFailure',
        defaultMessage: 'Failed to change Email',
    },
    editPhoneFailure: {
        id: 'UserProfilePersonalInfoEpicsBuilder_editPhoneFailure',
        defaultMessage: 'Failed to change Phone',
    },
    editFirstNameFailure: {
        id: 'UserProfilePersonalInfoEpicsBuilder_editFirstNameFailure',
        defaultMessage: 'Failed to change First Name',
    },
    editLastNameFailure: {
        id: 'UserProfilePersonalInfoEpicsBuilder_editLastNameFailure',
        defaultMessage: 'Failed to change Last Name',
    },
    editNicknameFailure: {
        id: 'UserProfilePersonalInfoEpicsBuilder_editNicknameFailure',
        defaultMessage: 'Failed to change Nickname',
    },
    editGenderFailure: {
        id: 'UserProfilePersonalInfoEpicsBuilder_editGenderFailure',
        defaultMessage: 'Failed to change Gender',
    },
    editDateOfBirthFailure: {
        id: 'UserProfilePersonalInfoEpicsBuilder_editDateOfBirthFailure',
        defaultMessage: 'Failed to change Date Of Birth',
    },
    editLicenseTypeSuccess: {
        id: 'UserProfilePersonalInfoEpicsBuilder_editLicenseTypeSuccess',
        defaultMessage: 'License Type and Country are successfully changed',
    },
    editLicenseTypeFailure: {
        id: 'UserProfilePersonalInfoEpicsBuilder_editLicenseTypeFailure',
        defaultMessage: 'Failed to change License Type and Country',
    },
});

@injectable()
export class UserProfilePersonalInfoEpicsBuilder extends BaseEpicsBuilder {
    private readonly _service: IUserProfileService;

    constructor(@inject(ServiceTypes.UserProfileService) service: IUserProfileService) {
        super();
        this._service = service;
    }

    protected buildEpicList(): Epic[] {
        return [
            this.buildEditEmailRequestEpic(),
            this.buildEditPhoneRequestEpic(),
            this.buildEditFirstNameRequestEpic(),
            this.buildEditLastNameRequestEpic(),
            this.buildEditNicknameRequestEpic(),
            this.buildEditGenderRequestEpic(),
            this.buildEditDateOfBirthRequestEpic(),
            this.buildEditLicenseTypeRequestEpic(),

            this.buildEditEmailSuccessEpic(),
            this.buildEditPhoneSuccessEpic(),
            this.buildEditFirstNameSuccessEpic(),
            this.buildEditLastNameSuccessEpic(),
            this.buildEditNicknameSuccessEpic(),
            this.buildEditGenderSuccessEpic(),
            this.buildEditDateOfBirthSuccessEpic(),
            this.buildEditLicenseTypeSuccessEpic(),

            this.buildEditEmailFailureEpic(),
            this.buildEditPhoneFailureEpic(),
            this.buildEditFirstNameFailureEpic(),
            this.buildEditLastNameFailureEpic(),
            this.buildEditNicknameFailureEpic(),
            this.buildEditGenderFailureEpic(),
            this.buildEditDateOfBirthFailureEpic(),
            this.buildEditLicenseTypeFailureEpic(),
        ];
    }

    private buildEditEmailRequestEpic(): Epic {
        return this.buildRequestEpic<EditPersonalInfoRequestPayload, EditEmailRequestModel, null>(personalInfoActions.editEmail, payload =>
            this._service.editEmail(payload.userId, payload.user.contact.email)
        );
    }

    private buildEditEmailSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(personalInfoActions.editEmail.success)),
                mergeMap(res => {
                    const {email, uid} = res.payload.requestPayload;
                    const updatedItem: Partial<UserProfileNormalized> = {contact: {email}};

                    return of(
                        showMessageAction({message: localized.editEmailSuccess, values: {email}}),
                        entityActions.updateItem({type: EntityType.UserProfile, id: uid, updatedItem})
                    );
                })
            );
    }

    private buildEditEmailFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(personalInfoActions.editEmail.failure)),
                map(() => showErrorAction({message: localized.editEmailFailure}))
            );
    }

    private buildEditPhoneRequestEpic(): Epic {
        return this.buildRequestEpic<EditPersonalInfoRequestPayload, EditPhoneRequestModel, null>(personalInfoActions.editPhone, payload =>
            this._service.editPhone(payload.userId, payload.user.contact.mobile.area, payload.user.contact.mobile.mobile)
        );
    }

    private buildEditPhoneSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(personalInfoActions.editPhone.success)),
                mergeMap((res: PayloadAction<string, ServiceResponsePayload<EditPhoneRequestModel, null>>) => {
                    const {area, mobile, uid} = res.payload.requestPayload;
                    const fullNumber = `${area}${mobile}`;
                    const updatedItem: Partial<UserProfileNormalized> = {
                        contact: {
                            mobile: {
                                full_number: fullNumber,
                                area: area,
                                mobile: mobile,
                            },
                        },
                    };

                    return of(
                        showMessageAction({message: localized.editPhoneSuccess, values: {phone: fullNumber}}),
                        entityActions.updateItem({type: EntityType.UserProfile, id: uid, updatedItem})
                    );
                })
            );
    }

    private buildEditPhoneFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(personalInfoActions.editPhone.failure)),
                map(() => showErrorAction({message: localized.editPhoneFailure}))
            );
    }

    private buildEditFirstNameRequestEpic(): Epic {
        return this.buildRequestEpic<EditPersonalInfoRequestPayload, GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>(
            personalInfoActions.editFirstName,
            (payload, _state) => {
                return this._service.updatePersonalInfo(payload.user);
            }
        );
    }

    private buildEditFirstNameSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(personalInfoActions.editFirstName.success)),
                mergeMap((res: PayloadAction<string, ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>>) => {
                    const {
                        user: {first_name},
                        uid,
                    } = res.payload.requestPayload.variables;
                    const updatedItem: Partial<UserProfileNormalized> = {first_name};

                    return of(
                        showMessageAction({message: localized.editFirstNameSuccess, values: {first_name}}),
                        entityActions.updateItem({type: EntityType.UserProfile, id: uid, updatedItem})
                    );
                })
            );
    }

    private buildEditFirstNameFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(personalInfoActions.editFirstName.failure)),
                map(() => showErrorAction({message: localized.editFirstNameFailure}))
            );
    }

    private buildEditLastNameRequestEpic(): Epic {
        return this.buildRequestEpic<EditPersonalInfoRequestPayload, GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>(
            personalInfoActions.editLastName,
            (payload, _state) => {
                return this._service.updatePersonalInfo(payload.user);
            }
        );
    }

    private buildEditLastNameSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(personalInfoActions.editLastName.success)),
                mergeMap((res: PayloadAction<string, ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>>) => {
                    const {
                        user: {last_name},
                        uid,
                    } = res.payload.requestPayload.variables;
                    const updatedItem: Partial<UserProfileNormalized> = {last_name};

                    return of(
                        showMessageAction({message: localized.editLastNameSuccess, values: {last_name}}),
                        entityActions.updateItem({type: EntityType.UserProfile, id: uid, updatedItem})
                    );
                })
            );
    }

    private buildEditLastNameFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(personalInfoActions.editLastName.failure)),
                map(() => showErrorAction({message: localized.editLastNameFailure}))
            );
    }

    private buildEditNicknameRequestEpic(): Epic {
        return this.buildRequestEpic<EditPersonalInfoRequestPayload, GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>(
            personalInfoActions.editNickname,
            (payload, _state) => {
                return this._service.updatePersonalInfo(payload.user);
            }
        );
    }

    private buildEditNicknameSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(personalInfoActions.editNickname.success)),
                mergeMap((res: PayloadAction<string, ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>>) => {
                    const {
                        user: {nickname},
                        uid,
                    } = res.payload.requestPayload.variables;
                    const updatedItem: Partial<UserProfileNormalized> = {nickname};

                    return of(
                        showMessageAction({message: localized.editNicknameSuccess, values: {nickname}}),
                        entityActions.updateItem({type: EntityType.UserProfile, id: uid, updatedItem})
                    );
                })
            );
    }

    private buildEditNicknameFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(personalInfoActions.editNickname.failure)),
                map(() => showErrorAction({message: localized.editNicknameFailure}))
            );
    }

    private buildEditGenderRequestEpic(): Epic {
        return this.buildRequestEpic<EditPersonalInfoRequestPayload, GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>(
            personalInfoActions.editGender,
            (payload, _state) => {
                return this._service.updatePersonalInfo(payload.user);
            }
        );
    }

    private buildEditGenderSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(personalInfoActions.editGender.success)),
                mergeMap((res: PayloadAction<string, ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>>) => {
                    const {
                        user: {gender},
                        uid,
                    } = res.payload.requestPayload.variables;
                    const updatedItem: Partial<UserProfileNormalized> = {gender};

                    return of(
                        showMessageAction({message: localized.editGenderSuccess, values: {gender}}),
                        entityActions.updateItem({type: EntityType.UserProfile, id: uid, updatedItem})
                    );
                })
            );
    }

    private buildEditGenderFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(personalInfoActions.editGender.failure)),
                map(() => showErrorAction({message: localized.editGenderFailure}))
            );
    }

    private buildEditDateOfBirthRequestEpic(): Epic {
        return this.buildRequestEpic<EditPersonalInfoRequestPayload, GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>(
            personalInfoActions.editDOB,
            (payload, _state) => {
                return this._service.updatePersonalInfo(payload.user);
            }
        );
    }

    private buildEditDateOfBirthSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(personalInfoActions.editDOB.success)),
                mergeMap((res: PayloadAction<string, ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>>) => {
                    const {
                        user: {birthday},
                        uid,
                    } = res.payload.requestPayload.variables;
                    const dateOfBirth = formatTimestamp(birthday, 'date');
                    const updatedItem: Partial<UserProfileNormalized> = {birthday};

                    return of(
                        showMessageAction({message: localized.editDateOfBirthSuccess, values: {dateOfBirth}}),
                        entityActions.updateItem({type: EntityType.UserProfile, id: uid, updatedItem})
                    );
                })
            );
    }

    private buildEditDateOfBirthFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(personalInfoActions.editDOB.failure)),
                map(() => showErrorAction({message: localized.editDateOfBirthFailure}))
            );
    }

    private buildEditLicenseTypeRequestEpic(): Epic {
        return this.buildRequestEpic<EditLicenseTypeRequestPayload, GqlMutationRequest<MutationUpdateUserLicenseArgs>, UserProfile>(
            personalInfoActions.editLicenseType,
            (payload, _state) => {
                return this._service.updateLicenseType(payload);
            }
        );
    }

    private buildEditLicenseTypeSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                ofType(personalInfoActions.editLicenseType.success),
                mergeMap(
                    (
                        res: PayloadAction<string, ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserLicenseArgs>, UserProfile>>
                    ) => {
                        const {
                            license: {license_type, state, country},
                            uid,
                        } = res.payload.requestPayload.variables;
                        const updatedItem: Partial<UserProfileNormalized> = {
                            license_type,
                            address: {
                                state,
                                country_info: country,
                            },
                        };

                        return of(
                            showMessageAction({message: localized.editLicenseTypeSuccess}),
                            entityActions.updateItem({type: EntityType.UserProfile, id: uid, updatedItem})
                        );
                    }
                )
            );
    }

    private buildEditLicenseTypeFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                ofType(personalInfoActions.editLicenseType.failure),
                map(() => showErrorAction({message: localized.editLicenseTypeFailure}))
            );
    }
}
