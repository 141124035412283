import {defineMessages} from 'react-intl';

import {LegalDocumentViewModelKeys} from '../types';

export const localizedLegalDocumentDataGrid = defineMessages<LegalDocumentViewModelKeys>({
    id: null,
    actionTime: {
        id: 'DataGridLegalDocument_actionTime',
        defaultMessage: 'Last Updated',
    },
    author: {
        id: 'DataGridLegalDocument_author',
        defaultMessage: 'User ID',
    },
    document: null,
    version: {
        id: 'DataGridLegalDocument_version',
        defaultMessage: 'Version',
    },
    licenseType: {
        id: 'DataGridLegalDocument_licenseType',
        defaultMessage: 'License Type',
    },
    type: null,
    required: null,
});
