import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'typesafe-actions';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';
import {Policy} from '@auth';

import {useJurisdictionConfig} from '../../../features/app/config/hooks';
import {withModule} from '../../../features/module-shared/components/ModuleHoc';
import {domain, LegalDocType} from '../types';

import data from './data';
import LegalDocumentModule from './LegalDocumentModule';

/**
 * @deprecated
 * <p>Should be removed</p>
 */
const TermsAndConditionsObsolete = () => {
    const {jurisdiction} = useJurisdictionConfig();
    const stateData = useSelector((s: RootState) => s.termsAndConditions);
    return (
        <LegalDocumentModule
            domain={domain.termsAndConditions}
            docType={LegalDocType.TermsAndConditions}
            jurisdiction={jurisdiction}
            docName={data.localized.termsAndConditions}
            data={stateData}
        />
    );
};

export const termsAndConditionsResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'TermsAndConditions',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.LegalContentManagement, null, 'read'),
        new Policy(ModuleName.LegalContentManagement, SubmoduleName.TermsAndConditions, 'read'),
    ]),
});

export default withModule(TermsAndConditionsObsolete, [
    {moduleName: ModuleName.LegalContentManagement, submoduleName: SubmoduleName.TermsAndConditions, permissions: [PermissionEnum.Read]},
]);
