import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {ModuleName, SubmoduleName} from '@models/modules';
import {Policy} from '@auth';

export const privacyPolicyReadResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'PrivacyPolicy',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.LegalContentManagement, null, 'read'),
        new Policy(ModuleName.LegalContentManagement, SubmoduleName.PrivacyPolicy, 'read'),
    ]),
});

export const privacyPolicyUpdateResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'PrivacyPolicy',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.LegalContentManagement, null, 'update'),
        new Policy(ModuleName.LegalContentManagement, SubmoduleName.PrivacyPolicy, 'update'),
    ]),
});

export const termsAndConditionsReadResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'TermsAndConditions',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.LegalContentManagement, null, 'read'),
        new Policy(ModuleName.LegalContentManagement, SubmoduleName.TermsAndConditions, 'read'),
    ]),
});

export const termsAndConditionsUpdateResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'TermsAndConditions',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.LegalContentManagement, null, 'update'),
        new Policy(ModuleName.LegalContentManagement, SubmoduleName.TermsAndConditions, 'update'),
    ]),
});
