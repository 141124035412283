import {GridSortModel} from '@mui/x-data-grid';

import {Sorting} from '../../../types';
import {DataGridColumn, DataGridProps, IModuleGridItem} from '../types';

import {GridPageChangeParams, GridSortModelParams, MuiDataGridProps} from './MuiDataGrid';

export class MuiDataGridPropsConverter<TModel extends IModuleGridItem, TColumnKey extends string, TColumn extends DataGridColumn<TModel>> {
    public convert(props: DataGridProps<TModel, TColumnKey, TColumn>): Omit<MuiDataGridProps, 'columns'> {
        return props.mode === 'server' ? this.getServerProps(props) : this.getClientProps(props);
    }

    private getServerProps(props: DataGridProps<TModel, TColumnKey, TColumn>): Omit<MuiDataGridProps, 'columns'> {
        return {
            ...this.getCommonProps(props),
            sortingMode: 'server',
            paginationMode: 'server',
        };
    }

    private getClientProps(props: DataGridProps<TModel, TColumnKey, TColumn>): Omit<MuiDataGridProps, 'columns'> {
        return {
            ...this.getCommonProps(props),
            sortingMode: 'client',
            paginationMode: 'client',
        };
    }

    private getCommonProps(props: DataGridProps<TModel, TColumnKey, TColumn>): Omit<MuiDataGridProps, 'columns'> {
        const getMuiSortModel = (sortModel: Sorting<string>[]): GridSortModel => {
            return sortModel?.map(s => ({
                field: s.field,
                sort: s.sort as 'asc' | 'desc' | undefined | null,
            }));
        };

        return {
            rowCount: props.paging?.rowCount,
            rows: props.rows,
            pinnedRow: props.pinnedRow,
            page: props.paging?.page,
            pageSize: props.paging?.pageSize,
            sortModel: getMuiSortModel(props.sortModel?.sortModel),
            onPageChange: (p: GridPageChangeParams) => props.onPageChange(p.page),
            onPageSizeChange: (p: GridPageChangeParams) => props.onPageSizeChange(p.pageSize),
            onSortModelChange: (p: GridSortModelParams) => props.onSortChange(p.sortModel),
            visibleColumns: null,
            onColumnsVisibilityUpdate: null,
            pinnedColumns: null,
            hasColumnSelection: false,
            isFlexible: props.isFlexible,
            hidePagination: props.hidePagination,
            className: props.className,
        };
    }
}
