import {MessageDescriptor} from 'react-intl';

import {EntityType} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';

import {Filter, Paging, SearchFilter, Sorting} from '../../types';

export const domain = 'view';

export const extendedViewCleanDelay = 600000;

export type ViewEnum =
    | 'View1'
    | 'View2'
    | 'View3'
    | 'View4'
    | 'CountryOfBirthAutocomplete'
    | 'NationalityAutocomplete'
    | 'CountryAutocomplete'
    | 'StateAutocomplete'
    | 'OccupationAutocomplete'
    | 'FinanceSummary'
    | 'MarketingInfo'
    | 'SecurityInfo'
    | 'WithdrawalPendingCases'
    | 'WithdrawalHistoryCases'
    | 'BulkExecutionSummary'
    | 'BulkTriggerBonusSelectUsers'
    | 'P2PReferralOfferingAdmin'
    | 'BlockPlayerLabels'
    | 'BlockPlayerLabelAssign'
    | 'BlockPlayerLabelsGroupModify'
    | 'GeoCheckHistory'
    | 'PlayerLabelGroupAutocomplete'
    | 'ActionHistoryGrid'
    | 'WithdrawalActions'
    | 'WithdrawalStatusButtons'
    | 'WithdrawalDetailsHeader'
    | 'KYCDetailsHeader'
    | 'PlayerAccessLocks'
    | 'FeatureLocks'
    | 'PersonalInfo'
    | 'AddressInfo'
    | 'BulkPlayerLabels'
    | 'BulkPlayerLabelAssign'
    | 'AggregatedFinanceSummary'
    | 'CountryFilter'
    | 'UserFinanceSummary'
    | 'UserRefereeRevenueInfo'
    | 'UserReferralInfo'
    | 'TrackingIdAutocomplete'
    | 'AgentFilter'
    | 'AgentAutocomplete'
    | 'PlayerLevelReport'
    | 'PlayerLevelReportFilters'
    | 'AgentManagementNGRReport'
    | 'AgentManagementNGRReportFilters'
    | 'AgentManagementBonusReport'
    | 'AgentManagementBonusReportFilters'
    | 'AgentManagementDeductionReport'
    | 'AgentManagementDeductionReportFilters'
    | 'AgentManagementTransferReport'
    | 'AgentManagementTransferReportFilters'
    | 'AgentVnDailyReport'
    | 'AgentVnWeeklyReport'
    | 'AgentPathStatistics'
    | 'AgentVnDailyReportFilters'
    | 'AgentVnWeeklyReportFilters'
    | 'AgentKrDailyReport'
    | 'UserDetailsPersonalInfo'
    | 'SecurityWorkspace'
    | 'KYCPendingCases'
    | 'KYCHistoryCases'
    | 'GameList'
    | 'GameDetails'
    | 'PlayerIdAutocomplete'
    | 'CustomerSupportWorkspace'
    | 'CustomerSupportDetailsHeader'
    | 'SecurityDetailsHeader'
    | 'TransactionHistory'
    | 'LoginHistory'
    | 'CustomerSupportDetailsActions'
    | 'InitiatePaymentKyc'
    | 'CustomerSupportDetails'
    | 'SecurityDetailsActions'
    | 'SecurityDetails'
    | 'WithdrawalDetails'
    | 'KYCDetails'
    | 'PlayersRelations'
    | 'CashBonuses'
    | 'BackpackInfo'
    | 'FinanceInfo'
    | 'EditableAgentPlayerId'
    | 'TeamWorkspaceAgent'
    | 'TeamWorkspaceReferee'
    | 'P2PTransfer'
    | 'ReferralLink'
    | 'CurrentBalance'
    | 'RedeemableAmount'
    | 'TeamWorkspaceFilters'
    | 'GameAdminPLO5Templates'
    | 'GameAdminTables'
    | 'GameTemplateModal'
    | 'GameTablePlayersGrid'
    | 'Notes'
    | 'PinnedNotes'
    | 'MarketingCodeReferrerIdAutocomplete'
    | 'UserReferralKeyValueTable'
    | 'UserReferralInfoForm'
    | 'Rules'
    | 'RuleModal'
    | 'NewRegistrationsChart'
    | 'ChooseBulkTriggerBonusButton'
    | 'AgentManagementReportKrDailyFilters'
    | 'FirstDayConversionChart'
    | 'ActiveDayRatioChart'
    | 'AvgLTVDayChart'
    | 'MarketingMetricBox'
    | 'AgentManagement'
    | 'DepositBonusCasinoSuppliersAutocomplete'
    | 'DepositBonusCasinoSuppliersAutocomplete.Total'
    | 'DepositBonusCasinoGamesAutocomplete'
    | 'DepositBonusCasinoGamesAutocomplete.Total'
    | 'BulkStepNotes'
    | 'AgentLevelReport'
    | 'KycActions'
    | 'UserProfileActions'
    | 'NDRPPendingCases'
    | 'NDRPHistoryCases'
    | 'NotesAttachments'
    | 'UserKYCInfo'
    | 'SportsbookTransactionHistory'
    | 'BulkKYCChooseActionStep'
    | 'BonusEngineDetails'
    | 'PlayerLevelEmbeddedReport'
    | 'AgentLevelEmbeddedReport'
    | 'TransferEmbeddedReport'
    | 'BonusEngine'
    | 'BulkMultiBonusSelectUsers'
    | 'LastBonus'
    | 'LastDeposit'
    /**
     * @deprecated <p>Should be removed</p>
     * Problem is accessing same entity with the same viewType with different filters string. Conflicting entity is UserProfile
     * Workaround was adding new viewType
     */
    | 'WDPendingPageView'
    /**
     * @deprecated <p>Should be removed</p>
     * Problem is accessing same entity with the same viewType with different filters string. Conflicting entity is UserProfile
     * Workaround was adding new viewType
     */
    | 'WDHistoryPageView'
    /**
     * @deprecated <p>Should be removed</p>
     * Problem is accessing same entity with the same viewType with different filters string. Conflicting entity is UserProfile
     * Workaround was adding new viewType
     */
    | 'KYCQueuePageView'
    | 'BulkExecutionSummaryOneClick'
    | 'BoUserSettingsView'
    | 'TermsAndConditions'
    | 'PrivacyPolicy'
    | 'VersionHistoryModal'
    | 'TermsAndConditionsDetails'
    | 'PrivacyPolicyDetails'
    | 'VersionHistoryDetailsModal';

export type ViewType = ViewEnum | `${ViewEnum}.${ViewEnum}`;

export type ViewRealtimeData = {
    entity: EntityType;
    triggers: {type: RealtimeMessageTrigger; args?: unknown}[];
    mode: RealtimeUpdatesMode;
};

export type ViewEntityRequestStatus = 'idle' | 'inProgress';

export type ViewEntityData<TFields extends string> = {
    entity: EntityType;
    fields: TFields[];
    filter?: string;
    keys?: string[];
    total?: number;
    status: ViewEntityRequestStatus;
};

export type ViewMetaProps = {
    viewType: ViewType;
    displayName?: MessageDescriptor;
    cleanDelay?: number;
    isCancelable?: false;
};

export type ViewData = ViewMetaProps & {
    entities: Partial<Record<EntityType, ViewEntityData<string>>>;
    realtime?: Partial<Record<EntityType, ViewRealtimeData>>;
};

type UseViewEntityBaseProps<TFilterKey extends string, TFields extends string> = ViewMetaProps & {
    fields: TFields[];
    realtimeMode?: RealtimeUpdatesMode;
    triggers?: {type: RealtimeMessageTrigger; args?: unknown}[];
    defaultFilters?: Filter<any, TFilterKey>[];
    defaultSorting?: Sorting<TFields>[];
    defaultPaging?: Paging;
    validateFilter: (filter: Filter<any, TFilterKey>[]) => boolean;
    syncWithUrl?: boolean;
    cleanDelay?: number;
    viewModelName?: string;
};

export type UseListViewEntityProps<TFilterKey extends string, TFields extends string> = UseViewEntityBaseProps<TFilterKey, TFields> & {
    ids?: string[];
};

// TODO: [BO-3024] The interface can be refactored
export type UseDetailsViewEntityProps<TFilterKey extends string, TFields extends string> = Omit<
    UseViewEntityBaseProps<TFilterKey, TFields>,
    'defaultSorting' | 'defaultPaging' | 'syncWithUrl' | 'triggers' | 'validateFilter'
> & {
    id?: string;
    validateFilter?: (filter: Filter<any, TFilterKey>[]) => boolean;
};

export type UseDependentDetailsViewEntityProps<TFilterKey extends string, TFields extends string> = Pick<
    UseViewEntityBaseProps<TFilterKey, TFields>,
    'fields' | 'realtimeMode' | 'defaultFilters' | 'validateFilter'
>;

type UseViewEntityBaseResult<TField extends string> = {
    searchFilter: SearchFilter;
    filterString: string;
    requestStatus?: ViewEntityRequestStatus;
    handlePageChange: (newPage: number) => void;
    handlePageSizeChange: (newPageSize: number) => void;
    handleSortChange: (newSorting: Sorting<TField>[]) => void;
    handleFilterChange: (newFilter: Filter<any, string>[]) => void;
    forceViewUpdate?: () => void;
};

export type UseListViewEntityResult<TItem, TField extends string> = UseViewEntityBaseResult<TField> & {
    items: TItem[];
    totalCount: number;
};

export type UseDetailsViewEntityResult<TItem, TField extends string> = Omit<
    UseViewEntityBaseResult<TField>,
    'handlePageChange' | 'handlePageSizeChange' | 'handleSortChange'
> & {
    item: TItem;
};

export const schemaIdKey = 'id';
export type ViewModel<TModel> = TModel & {[schemaIdKey]?: string};
