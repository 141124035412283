import React from 'react';

import {withPolicyAccessCheck} from '@access-control';
import {ErrorModal} from '@components/error';
import {LegalDocType} from '@models';
import {withTraceErrorBoundary} from '@otel';

import {LegalDocumentQueue, termsAndConditionsReadResource} from 'src/features/block-legal-document';
import {useJurisdictionFeature} from '../features/app/config/hooks';
import {FeatureName} from '../features/app/config/types';

import TermsAndConditionsObsolete from './legal-docs/components/TermsAndConditions';

function TermsAndConditions() {
    return (
        <LegalDocumentQueue
            document={LegalDocType.TermsAndConditions}
            viewType="TermsAndConditions"
            fields={['licenseType', 'version', 'actionTime']}
        />
    );
}

function TermsAndConditionsInner() {
    const licenseFeatureAvailable = useJurisdictionFeature({featureName: FeatureName.LegalDocumentWithLicense});
    return licenseFeatureAvailable ? <TermsAndConditions /> : <TermsAndConditionsObsolete />;
}

export default withTraceErrorBoundary(withPolicyAccessCheck(TermsAndConditionsInner, termsAndConditionsReadResource), ErrorModal);
