import React from 'react';
import {Box} from '@mui/material';
import {GridCellParams} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import {Chip} from '@components/chip/Chips';
import {ChipType, ChipVariant} from '@components/chip/types';
import {EmptyLabel} from '@components/label';
import StyledTooltip from '@components/Tooltip';
import {formatDate} from '@utils';

import {LegalDocType} from '../types';
import {getVersionDocumentsData} from '../utils';

const useClasses = makeStyles()(theme => ({
    versionAcceptanceDocumentContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    versionAcceptanceDocumentDate: {
        marginRight: theme.spacing(1),
    },
    versionAcceptanceDocumentDatePrevious: {
        '&:not(:last-of-type)': {
            marginBottom: theme.spacing(2),
        },
    },
}));

export const renderVersionAcceptanceDocumentAcceptanceDate = (params: GridCellParams, documentType: LegalDocType) => {
    const {classes} = useClasses();

    const {latestDocument, previousDocuments} = getVersionDocumentsData(params, documentType);

    return latestDocument ? (
        <StyledTooltip
            element={
                <Box className={classes.versionAcceptanceDocumentContainer}>
                    <Box className={classes.versionAcceptanceDocumentDate}>{formatDate(latestDocument?.acceptedAt, 'date-time')}</Box>
                    {previousDocuments?.length ? (
                        <Chip
                            chipType={ChipType.Status}
                            chipVariant={ChipVariant.Grey}
                            label={`+${previousDocuments?.length}`}
                            fillWidth={false}
                            size="small"
                        />
                    ) : null}
                </Box>
            }
            title={
                <Box>
                    {previousDocuments?.length ? (
                        <Box>
                            {previousDocuments?.map(document => (
                                <Box key={document.id} className={classes.versionAcceptanceDocumentDatePrevious}>
                                    <Box>{formatDate(document?.acceptedAt, 'date-time')}</Box>
                                </Box>
                            ))}
                        </Box>
                    ) : null}
                </Box>
            }
            hideTooltip={!previousDocuments?.length}
        />
    ) : (
        <EmptyLabel defaultSymbol="dash" />
    );
};

export default renderVersionAcceptanceDocumentAcceptanceDate;
