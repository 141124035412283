import {createAsyncAction} from 'typesafe-actions';

import {EditLegalDocumentResponse, LegalDocumentEditModel} from '@services/rest-api/legalDocumentApiService';

const domain = 'legal-document';

export const legalDocumentActions = {
    editLegalDocument: createAsyncAction(
        `${domain}/editLegalDocumentRequest`,
        `${domain}/editLegalDocumentSucceed`,
        `${domain}/editLegalDocumentFailure`
    )<LegalDocumentEditModel, EditLegalDocumentResponse, EditLegalDocumentResponse>(),
};
