import React from 'react';
import {Controller, FormProvider} from 'react-hook-form';
import {defineMessages} from 'react-intl';
import {Checkbox, FormControlLabel} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import Button from '@components/button/Buttons';
import {LocalizedText} from '@components/i18n';
import {RuleType, useValidationFormatter} from '@components/input';
import {HTMLEditor} from '@components/rich-text-editor';
import {LegalDocType, License} from '@models';
import {LegalDocumentEditModel} from '@services/rest-api/legalDocumentApiService';

import {useJurisdictionConfig} from '../../app/config/hooks';
import {useReduxForm} from '../../shared/form/hooks';
import {legalDocumentActions} from '../actions';
import {LegalDocumentViewModel} from '../types';

const localized = defineMessages({
    documentContent: {
        id: 'LegalDocs_documentContent',
        defaultMessage: 'Document content',
    },
    isRequired: {
        id: 'LegalDocs_isRequired',
        defaultMessage: 'Is required',
    },
    submitButton: {
        id: 'LegalDocs_submitButton',
        defaultMessage: 'Submit',
    },
    cancelButton: {
        id: 'LegalDocs_cancelButton',
        defaultMessage: 'Cancel',
    },
});

const useClasses = makeStyles()(theme => ({
    legalDocumentFormContent: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
        height: `calc(100% - ${theme.typography.h6.lineHeight})`,
        margin: theme.spacing(0, 2),
    },
    legalDocumentFormContentFullscreenInput: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    legalDocumentFormContentIsRequiredInput: {
        margin: 0,
    },
    legalDocumentFormContentButtonsContainer: {
        display: 'flex',
        justifyContent: 'end',
        gap: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
}));

type LegalDocumentFormContentProps = {
    document: string;
    required: boolean;
    documentType: LegalDocType;
    licenseType: License;
    onClose: () => void;
    onSuccess: () => void;
};

export function LegalDocumentFormContent({
    document,
    required,
    documentType,
    licenseType,
    onClose,
    onSuccess,
}: LegalDocumentFormContentProps) {
    const {classes} = useClasses();
    const validationFormatter = useValidationFormatter();
    const {jurisdiction} = useJurisdictionConfig();
    const form = useReduxForm<Pick<LegalDocumentViewModel, 'document' | 'required'>, LegalDocumentEditModel>({
        initialModel: {document, required},
        asyncAction: legalDocumentActions.editLegalDocument,
        map: formModel => ({
            id: `${jurisdiction}/${documentType}`,
            document: formModel.document,
            required: formModel.required,
            key: {
                jurisdiction,
                name: documentType,
                licenseType,
            },
        }),
        onSuccess,
    });

    const isProgress = form?.state?.isProgress;

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(form.submit)} className={classes.legalDocumentFormContent}>
                <Controller
                    render={({field, fieldState}) => (
                        <div className={classes.legalDocumentFormContentFullscreenInput}>
                            <HTMLEditor
                                error={fieldState?.error?.message}
                                value={field.value}
                                onChange={(value: string) => field.onChange(value)}
                            />
                        </div>
                    )}
                    name="document"
                    control={form.control}
                    defaultValue={document}
                    rules={{
                        required: validationFormatter(RuleType.Required, localized.documentContent),
                    }}
                />
                <Controller
                    render={({field}) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={field.value as boolean}
                                    onChange={e => field.onChange(e.target.checked)}
                                    color="secondary"
                                />
                            }
                            label={<LocalizedText label={localized.isRequired} />}
                        />
                    )}
                    name="required"
                    control={form.control}
                    defaultValue={required ?? true}
                />
                <div className={classes.legalDocumentFormContentButtonsContainer}>
                    <Button type="submit" color="primary" label={localized.submitButton} loading={isProgress} />
                    <Button type="submit" label={localized.cancelButton} onClick={onClose} disabled={isProgress} />
                </div>
            </form>
        </FormProvider>
    );
}
