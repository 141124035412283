import React from 'react';
import {useDispatch} from 'react-redux';
import {GridCellParams} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import {OutlinedButton} from '@components/button/Buttons';
import {ActionButtonColumnValue} from '@components/data-grid/types';

const useStyles = makeStyles()(theme => ({
    muiGridActionButtonCell: {
        width: '80px',
        height: '32px',
        borderRadius: theme.shape.borderRadius * 2,
    },
}));

export function renderMuiGridActionButtonCell(params: GridCellParams) {
    const dispatch = useDispatch();
    const {classes} = useStyles();
    const {label, action} = params.value as ActionButtonColumnValue;
    return <OutlinedButton label={label} onClick={() => dispatch(action)} className={classes.muiGridActionButtonCell} />;
}
