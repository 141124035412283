import React, {useEffect} from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import {LegalDocType, License} from '@models';
import {EntityType, LegalDocHistoryFilterKeys} from '@redux/entity';
import {extendedViewCleanDelay, getNonEmptyValueValidator, useView, ViewType} from '@redux/view';
import {CustomTheme} from '@style';
import {mapSortingToFilter, updateKeyValueFilterArray} from '@utils';
import {getFilterString} from '@utils/query';

import {Filter, Sorting} from 'src/common/types';
import {useLegalDocHistory} from '../hooks';
import {LegalDocHistoryViewModelKeys} from '../types';

import {DataGridLegalDocHistory, LegalDocHistoryGridViewModelKeys} from './DataGridLegalDocHistory';

const localized = defineMessages({
    title: {
        id: 'VersionHistoryModal_title',
        defaultMessage: 'Version History',
    },
});

const useStyles = makeStyles()((theme: CustomTheme) => ({
    versionHistoryModal: {
        minHeight: theme.custom.modalContainerMinHeight,
    },
}));

export type VersionHistoryModalProps = {
    title: string | MessageDescriptor;
    licenseType: License;
    documentType: LegalDocType;
};

export function VersionHistoryModal({licenseType, documentType}: VersionHistoryModalProps) {
    const {classes} = useStyles();
    const viewType: ViewType = 'VersionHistoryModal';
    const fields: LegalDocHistoryViewModelKeys[] = ['version', 'created_at', 'user_id', 'user_email', 'ip', 'license_type', 'document'];
    const keys: LegalDocHistoryGridViewModelKeys[] = ['version', 'created_at', 'user_id', 'user_email', 'ip', 'view_document'];
    const defaultSorting: Sorting<LegalDocHistoryViewModelKeys> = {sort: 'desc', field: 'version'};
    const defaultFilters: Filter<unknown, LegalDocHistoryFilterKeys>[] = getDefaultFilters();
    const {items, totalCount, searchFilter, handlePageSizeChange, handleSortChange, handlePageChange} = useLegalDocHistory({
        viewType,
        fields,
        displayName: localized.title,
        defaultFilters: defaultFilters,
        defaultSorting: [defaultSorting],
        validateFilter: getNonEmptyValueValidator<LegalDocHistoryFilterKeys>('document_name', 'license_type'),
        cleanDelay: extendedViewCleanDelay,
    });
    const {forceUpdateFilter} = useView(viewType);

    useEffect(() => {
        const sortingFilter = mapSortingToFilter(defaultSorting);
        const filter = updateKeyValueFilterArray(defaultFilters, ...sortingFilter);
        forceUpdateFilter(EntityType.LegalDocHistory, getFilterString('', false, ...filter));
    }, []);

    function getDefaultFilters() {
        const documentFilterKey: LegalDocHistoryFilterKeys = 'document_name';
        const licenseFilterKey: LegalDocHistoryFilterKeys = 'license_type';
        return [
            {key: documentFilterKey, value: documentType},
            {key: licenseFilterKey, value: licenseType},
        ];
    }

    return (
        <DataGridLegalDocHistory
            className={classes.versionHistoryModal}
            columns={keys}
            rows={items}
            paging={{page: searchFilter?.paging?.page, pageSize: searchFilter?.paging?.pageSize, rowCount: totalCount}}
            sortModel={{sortModel: searchFilter?.sorting}}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onSortChange={handleSortChange}
            isFlexible
        />
    );
}
