import {DocumentNode} from '@apollo/client';
import {AjaxRequest} from 'rxjs/ajax';

import {Query} from '@models/generated/graphql';
import {EntityFetchRequestPayload} from '@redux/entity';
import {AjaxResponse} from '@services/rest-api';

export type HttpHeaders = {
    Authorization?: string;
};

export enum HttpMethod {
    Post = 'POST',
    Delete = 'DELETE',
    Get = 'GET',
    Put = 'PUT',
    Patch = 'PATCH',
}

export enum HttpStatusCode {
    Ok = 200,
    Created = 201,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    InternalServerError = 500,
}

const serverBaseErrorCode = 10000000;
const clientBaseErrorCode = 20000000;

export enum CustomErrorCodes {
    FileUploadFailed = clientBaseErrorCode + 1,
    FetchRequestFailure = clientBaseErrorCode + 2,
    InputValidationIncorrectFormat = serverBaseErrorCode + 6,
    InputValidationValueNotExist = serverBaseErrorCode + 27,
    InputValidationNullValue = serverBaseErrorCode + 28,
    InvalidNotificationContractType = serverBaseErrorCode + 48,
    UnexpectedTransactionStatus = serverBaseErrorCode + 50,
    TransactionWithSameIdAlreadyExists = serverBaseErrorCode + 56,
    HandledExceptionOccurred = serverBaseErrorCode + 58,
    NotEnoughFunds = serverBaseErrorCode + 62,
    InvalidSubagentRevenueShare = clientBaseErrorCode + 63,
    NoParentAgentRevenueShare = clientBaseErrorCode + 64,
    StatusChangedButLoginAccessFailed = clientBaseErrorCode + 65,
}

export enum ServerResponseErrorCode {
    BadRequest = '400',
    Unauthorized = '401',
    Forbidden = '403',
    NotFound = '404',
    InternalServerError = '500',

    //Custom codes
    MarketingCodeAlreadyAvailable = 'MarketingCodeAlreadyAvailable',
}

export type ServerResponseError = {
    code: string;
    message: string;
    values: string[];
    statusCode?: number;
    traceId?: string;
    data?: object;
    validation_errors?: string;
    request_errors?: string;
};

export enum ServerResponseStatus {
    Success = 'Success',
    Failed = 'Failed',
    NoPermissions = 'NoPermissions',
    NotAuthorized = 'NotAuthorized',
}

export interface IServiceErrorResponsePayload {
    status: ServerResponseStatus;
    message?: string;
    errors?: ServerResponseError[];
}

export class ServiceResponsePayload<TRequestPayload, TResponsePayload> implements IServiceErrorResponsePayload {
    status: ServerResponseStatus;
    message?: string;
    errors?: ServerResponseError[];
    requestPayload: TRequestPayload;
    responsePayload: TResponsePayload;
}

export type GqlRequest<TArgs = unknown> = {
    query: DocumentNode;
    variables: TArgs;
};

export type GqlMutationRequest<TArgs = unknown> = {
    mutation: DocumentNode;
    variables: TArgs;
};

export class GqlResponse<TQueryResponse> extends ServiceResponsePayload<GqlRequest, TQueryResponse> {}

export class GqlQueryResponse extends GqlResponse<Query> {}

export type GqlMutationResponse<TMutation, TArgs> = ServiceResponsePayload<GqlMutationRequest<TArgs>, TMutation>;

export type RestRequest = Partial<Pick<AjaxRequest, 'withCredentials' | 'headers'>> & {
    endpoint: string;
    query?: string;
    body?: any;
};

export class RestResponse extends ServiceResponsePayload<RestRequest, AjaxResponse | any> {}

export const maxPageSize = 3000;

export interface IRequestParser<T> {
    parse(payload: EntityFetchRequestPayload): T;
}
