import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';

import {ServiceTypes} from '@inversify';
import {LegalContentManagementHistoryFilter, QueryGetLegalContentHistoryArgs} from '@models/generated/businessPoolGraphql';
import {Filter, LegalDocHistoryFilterKeys, LegalDocHistoryQueryFields} from '@redux/entity';
import {EntityBaseGqlService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';

@injectable()
export class LegalDocService extends EntityBaseGqlService<
    QueryGetLegalContentHistoryArgs,
    LegalDocHistoryQueryFields,
    LegalDocHistoryFilterKeys
> {
    constructor(
        @inject(ServiceTypes.ApolloClientPE) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        super(client, mapper, new LegalDocHistoryRequestBuilder());
    }
}

export class LegalDocHistoryRequestBuilder extends GqlRequestBuilder<
    QueryGetLegalContentHistoryArgs,
    LegalDocHistoryQueryFields,
    LegalDocHistoryFilterKeys
> {
    public buildQuery(fields: LegalDocHistoryQueryFields[]): DocumentNode {
        return gql`
            query GetLegalContentHistory($filter: LegalContentManagementHistoryFilter, $sort: Sorting, $start: Int, $end: Int) {
                getLegalContentHistory(filter: $filter, sort: $sort, start: $start, end: $end) {
                    ${this.buildQueryFields(fields)}
                }
            }
        `;
    }

    protected buildFilter(filter: Filter<LegalDocHistoryFilterKeys>): {filter: LegalContentManagementHistoryFilter} {
        return {
            filter: {
                document_name: this.toGQLStringFilter(filter, 'document_name'),
                license_type: this.toGQLStringFilter(filter, 'license_type'),
                version: this.toNumberFilter(filter, 'version'),
            },
        };
    }
}
