import {defineMessages} from 'react-intl';

export const localized = defineMessages({
    piiAddressWrongCountry: {
        id: 'piiAddressWrongCountry',
        defaultMessage: '{country} country can not be used under {license} license',
    },
    piiAddressCountryFieldName: {
        id: 'piiAddressCountryFieldName',
        defaultMessage: 'Country',
    },
    piiAddressStateFieldName: {
        id: 'piiAddressStateFieldName',
        defaultMessage: 'State',
    },
});
