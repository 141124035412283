import React, {memo, useContext, useRef} from 'react';
import {IntlContext, MessageDescriptor} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@mui/material';
import {GridCellParams} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';
import {RootState} from 'typesafe-actions';

import {Chip} from '@components/chip/Chips';
import {ChipType, ChipVariant} from '@components/chip/types';
import LocalizedText from '@components/i18n/LocalizedText';
import {EmptyLabel} from '@components/label';
import {LinkText} from '@components/link';
import {StyledTooltip} from '@components/Tooltip';

import {useJurisdictionFeature} from '../../../features/app/config/hooks';
import {FeatureName} from '../../../features/app/config/types';
import {GridModalActionProps, GridModalProps, withGridModal} from '../../../features/data-grids/components/GridModalHoc';
import {getGridRowData} from '../../../features/module-temp-storage/components/editable/utils';
import {userAcceptanceVersionActions} from '../actions';
import {LegalDocType, VersionAcceptanceDocument, VersionAcceptanceGridItem} from '../types';
import {getVersionDocumentsData} from '../utils';

import data from './data';
import LegalDocumentView from './LegalDocumentView';

export const VersionAcceptanceAction = ({options, onClick}: GridModalActionProps<VersionAcceptanceOptions>): JSX.Element => {
    const {latestDocument} = options;
    const {formatMessage} = useContext(IntlContext);
    const dispatch = useDispatch();
    const licenseFeatureAvailable = useJurisdictionFeature({featureName: FeatureName.LegalDocumentWithLicense});

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>) => {
        dispatch(userAcceptanceVersionActions.loadDocument.request(latestDocument));
        onClick(event);
    };

    const getLabel = () => {
        return formatMessage(data.localized.versionLabel, {version: latestDocument.version});
    };

    return !latestDocument ? null : (
        <Box data-testid="versionAcceptanceAction">
            {licenseFeatureAvailable && !latestDocument?.licenseType ? (
                <LocalizedText label={getLabel()} />
            ) : (
                <LinkText title={getLabel()} onClick={handleClick} />
            )}
        </Box>
    );
};

const VersionAcceptanceModal = ({options}: GridModalProps<any, any, VersionAcceptanceOptions>): JSX.Element => {
    const modalId = useRef('versionAcceptanceModal');
    const documentContent = useSelector((state: RootState) => state.userAcceptance.data.documentContent);

    return (
        <Box id={modalId.current}>
            {documentContent && <LegalDocumentView fullContent={true} document={documentContent} documentName={options.documentName} />}
        </Box>
    );
};

type VersionAcceptanceOptions = {
    documentName: MessageDescriptor;
    latestDocument: VersionAcceptanceDocument;
};

const VersionAcceptance = memo(withGridModal<any, any, VersionAcceptanceOptions>(VersionAcceptanceAction, VersionAcceptanceModal));

type VersionAcceptanceDocumentLinkProps = {
    id: string;
    options: VersionAcceptanceOptions;
};

const VersionAcceptanceDocumentLink = ({id, options}: VersionAcceptanceDocumentLinkProps): JSX.Element => {
    return (
        <VersionAcceptance
            modalStyleParams={{maxWidth: 'lg'}}
            id={id}
            title={<LocalizedText label={data.localized.versionHistoryLabel} />}
            options={options}
        />
    );
};

const useClasses = makeStyles()(theme => ({
    versionAcceptanceDocumentContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    versionAcceptanceDocument: {
        marginRight: theme.spacing(1),
    },
    versionAcceptanceDocumentPrevious: {
        '&:not(:last-of-type)': {
            marginBottom: theme.spacing(2),
        },
    },
}));

export const renderVersionAcceptanceCell = (params: GridCellParams, documentType: LegalDocType) => {
    const {classes} = useClasses();
    const {id} = getGridRowData<VersionAcceptanceGridItem>(params);
    const {latestDocument, previousDocuments} = getVersionDocumentsData(params, documentType);

    const documentName: MessageDescriptor =
        documentType === LegalDocType.PrivacyPolicy ? data.localized.privacyPolicy : data.localized.termsAndConditions;

    return latestDocument ? (
        <StyledTooltip
            element={
                <Box className={classes.versionAcceptanceDocumentContainer}>
                    <Box className={classes.versionAcceptanceDocument}>
                        <VersionAcceptanceDocumentLink id={id} options={{documentName, latestDocument}} />
                    </Box>
                    {previousDocuments?.length ? (
                        <Chip
                            chipType={ChipType.Status}
                            chipVariant={ChipVariant.Grey}
                            label={`+${previousDocuments?.length}`}
                            fillWidth={false}
                            size="small"
                        />
                    ) : null}
                </Box>
            }
            title={
                <Box>
                    {previousDocuments?.length ? (
                        <Box>
                            {previousDocuments?.map(document => (
                                <Box key={document.id} className={classes.versionAcceptanceDocumentPrevious}>
                                    <VersionAcceptanceDocumentLink id={id} options={{documentName, latestDocument: document}} />
                                </Box>
                            ))}
                        </Box>
                    ) : null}
                </Box>
            }
            hideTooltip={!previousDocuments?.length}
        />
    ) : (
        <EmptyLabel defaultSymbol="dash" />
    );
};
