import {useAutoMapper} from '@auto-mapper';
import {LegalDocType} from '@models';
import {LegalManagementHistory} from '@models/generated/businessPoolGraphql';
import {EntityType, LegalDocHistoryFilterKeys, LegalDocHistoryQueryFields} from '@redux/entity';
import {UseListViewEntityProps, UseListViewEntityResult, useViewInit} from '@redux/view';

import {Sorting} from '../../common/types';
import {useBoUsers} from '../block-bo-user';

import {LegalDocHistorySortingFields, LegalDocHistoryViewModel, LegalDocHistoryViewModelKeys} from './types';

export function useLegalDocHistory({
    viewType,
    fields,
    displayName,
    cleanDelay = 0,
    defaultFilters,
    defaultPaging,
    defaultSorting,
    validateFilter,
    syncWithUrl = false,
}: UseListViewEntityProps<LegalDocHistoryFilterKeys, LegalDocHistoryViewModelKeys>): UseListViewEntityResult<
    LegalDocHistoryViewModel,
    LegalDocHistoryViewModelKeys
> {
    const mapper = useAutoMapper();

    const queryFields: LegalDocHistoryQueryFields[] = mapper.map<LegalDocHistoryViewModelKeys[], LegalDocHistoryQueryFields[]>(
        fields,
        nameof<LegalDocHistoryViewModelKeys>(),
        nameof<LegalDocHistoryQueryFields>()
    );

    const {
        items,
        totalCount,
        searchFilter,
        viewEntity: {filter: filterString},
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    } = useViewInit<LegalManagementHistory, LegalDocHistoryFilterKeys, LegalDocHistoryQueryFields, LegalDocHistorySortingFields>({
        viewType,
        displayName,
        entity: {
            entity: EntityType.LegalDocHistory,
            fields: queryFields,
        },
        defaultFilters,
        defaultSorting: mapSorting(defaultSorting),
        defaultPaging,
        syncWithUrl,
        validateFilter,
        cleanDelay,
    });

    const usersIds = items?.map(i => i?.user_id)?.filter(i => i);
    const {items: boUsers} = useBoUsers({
        viewType,
        fields: ['id', 'email'],
        ids: usersIds,
        validateFilter: () => !!usersIds?.length,
    });

    const documents = items?.map(i => {
        const legalDoc = mapper.map(i, LegalManagementHistory, LegalDocHistoryViewModel);
        const user_email = boUsers?.find(u => u.id === legalDoc.user_id)?.email;
        const type: LegalDocType = defaultFilters?.find(f => f.key === 'document_name')?.value;
        return {...legalDoc, user_email, type};
    });

    return {
        items: documents,
        totalCount,
        searchFilter,
        filterString,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange: handleVmSortChange,
        handleFilterChange,
    };

    function mapSortField(field: LegalDocHistoryViewModelKeys): LegalDocHistorySortingFields {
        return mapper.map<LegalDocHistoryViewModelKeys, LegalDocHistorySortingFields>(
            field,
            nameof<LegalDocHistoryViewModelKeys>(),
            nameof<LegalDocHistorySortingFields>()
        );
    }

    function mapSorting(newSorting: Sorting<LegalDocHistoryViewModelKeys>[]): Sorting<LegalDocHistorySortingFields>[] {
        return newSorting?.map(s => ({...s, field: mapSortField(s.field)}));
    }

    function handleVmSortChange(newSorting: Sorting<LegalDocHistoryViewModelKeys>[]) {
        handleSortChange(mapSorting(newSorting));
    }
}
