import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import {HtmlContentViewer} from '@components/HtmlContentViewer';
import {LocalizedText} from '@components/i18n';
import {LegalDocType} from '@models';
import {CustomTheme} from '@style';

const localized = defineMessages({
    versionLabel: {
        id: 'LegalDocs_versionLabel',
        defaultMessage: 'version {version}',
    },
    required: {
        id: 'LegalDocs_required',
        defaultMessage: 'required',
    },
});

const useClasses = makeStyles()((theme: CustomTheme) => ({
    legalDocumentViewContainer: {
        margin: theme.spacing(0, 2),
        backgroundColor: theme.custom.palette.content.default,
        position: 'relative',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    legalDocumentViewContentWrapper: {
        padding: theme.spacing(2),
        overflow: 'auto',
    },
    legalDocumentViewContentWrapperUnlimited: {
        maxHeight: 'none',
    },
    legalDocumentViewContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
    },
    legalDocumentViewHeader: {
        display: 'flex',
        padding: theme.spacing(2),
        backgroundColor: theme.custom.palette.content.header,
        color: theme.palette.primary.contrastText,
        position: 'relative',
    },
    legalDocumentViewHeaderTitle: {
        fontSize: '1.5em',
        fontWeight: 'bold',
        textAlign: 'center',
        width: '100%',
    },
    legalDocumentViewHeaderVersion: {
        padding: theme.spacing(0, 2),
        margin: theme.spacing('auto', '0', 'auto', 'auto'),
        whiteSpace: 'nowrap',
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    },
}));

export type LegalDocumentViewProps = {
    documentType: LegalDocType;
    content: string;
    required: boolean;
    version: number;
    name: MessageDescriptor;
    fullContent?: boolean;
};

export function LegalDocumentView({content, required, version, name, fullContent}: LegalDocumentViewProps) {
    const {classes, cx} = useClasses();
    return (
        <div className={classes.legalDocumentViewContainer}>
            <div className={classes.legalDocumentViewHeader}>
                <div className={classes.legalDocumentViewHeaderTitle}>
                    <LocalizedText label={name} />
                    {required ? (
                        <span>
                            {' '}
                            <LocalizedText label={localized.required} />
                        </span>
                    ) : null}
                </div>
                <div className={classes.legalDocumentViewHeaderVersion}>
                    <LocalizedText label={localized.versionLabel} labelParams={{version}} />
                </div>
            </div>
            <div className={cx(classes.legalDocumentViewContentWrapper, fullContent && classes.legalDocumentViewContentWrapperUnlimited)}>
                <HtmlContentViewer className={classes.legalDocumentViewContent} content={content} />
            </div>
        </div>
    );
}
