import {LegalDocType, License} from '@models';

export class LegalDocHistoryViewModel {
    id: string;
    user_id: string;
    version: number;
    created_at: Date;
    ip: string;
    document: string;
    license_type: License;
    user_email?: string;
    type: LegalDocType;
}

export type LegalDocHistoryViewModelKeys = keyof LegalDocHistoryViewModel;

export type LegalDocHistorySortingFields = LegalDocHistoryViewModelKeys;
