import React from 'react';
import {defineMessages} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import {LegalDocType, License} from '@models';
import {LegalDocumentFilterKeys} from '@redux/entity';
import {getNonEmptyValueValidator} from '@redux/view';

import {LegalDocumentView, useLegalDocuments} from 'src/features/block-legal-document';

import {VersionHistoryModalButton} from './VersionHistoryModalButton';

const localized = defineMessages({
    backButtonLabel: {
        id: 'VersionHistoryDetailsModal_backButtonLabel',
        defaultMessage: 'Back',
    },
    termsAndConditionsFileName: {
        id: 'VersionHistoryDetailsModal_termsAndConditionsFileName',
        defaultMessage: 'Terms & Conditions',
    },
    privacyPolicyFileName: {
        id: 'VersionHistoryDetailsModal_privacyPolicyFileName',
        defaultMessage: 'Privacy Policy',
    },
});

const useStyles = makeStyles()(theme => ({
    versionHistoryDetailsModalButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(2, 0),
    },
}));

export type VersionHistoryDetailsModalProps = {documentType: LegalDocType; licenseType: License; version: number};

export function VersionHistoryDetailsModal({documentType, licenseType, version}: VersionHistoryDetailsModalProps) {
    const {classes} = useStyles();
    const title = documentType === LegalDocType.TermsAndConditions ? localized.termsAndConditionsFileName : localized.privacyPolicyFileName;
    const {items} = useLegalDocuments({
        viewType: 'VersionHistoryDetailsModal',
        fields: ['licenseType', 'version', 'actionTime', 'document', 'type'],
        displayName: title,
        defaultFilters: [
            {key: 'document', value: documentType},
            {key: 'licenseType', value: licenseType},
            {key: 'version', value: version},
        ],
        validateFilter: getNonEmptyValueValidator<LegalDocumentFilterKeys>('document', 'licenseType', 'version'),
    });
    const item = items?.[0];

    return (
        <>
            <LegalDocumentView
                documentType={item?.type}
                content={item?.document}
                name={title}
                required={item?.required}
                version={item?.version}
            />
            <div className={classes.versionHistoryDetailsModalButtonContainer}>
                <VersionHistoryModalButton label={localized.backButtonLabel} documentType={documentType} licenseType={licenseType} />
            </div>
        </>
    );
}
