import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';

import {ServiceTypes} from '@inversify';
import {QueryGetAggregatedFinanceSummaryArgs} from '@models/generated/graphql';
import {AggregatedFinanceSummaryFilterKeys, AggregatedFinanceSummaryQueryFields, Filter} from '@redux/entity';
import {EntityBaseGqlService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';

@injectable()
export class AggregatedFinanceSummaryService extends EntityBaseGqlService<
    QueryGetAggregatedFinanceSummaryArgs,
    AggregatedFinanceSummaryQueryFields,
    AggregatedFinanceSummaryFilterKeys
> {
    constructor(
        @inject(ServiceTypes.ApolloClientIGP) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        super(client, mapper, new AggregatedFinanceSummaryRequestBuilder());
    }
}

export class AggregatedFinanceSummaryRequestBuilder extends GqlRequestBuilder<
    QueryGetAggregatedFinanceSummaryArgs,
    AggregatedFinanceSummaryQueryFields,
    AggregatedFinanceSummaryFilterKeys
> {
    public buildQuery = (fields: AggregatedFinanceSummaryQueryFields[]): DocumentNode => gql`
        query GetAggregatedFinanceSummary($filter: AggregatedFinanceSummaryFilterInput) {
            getAggregatedFinanceSummary(filter: $filter) {
                uid
                start_at @include(if: ${this.hasField(fields, 'start_at.seconds')}) {
                    seconds
                }
                end_at @include(if: ${this.hasField(fields, 'end_at.seconds')}) {
                    seconds
                }
                bonus_ratio @include(if: ${this.hasField(fields, 'bonus_ratio')})
                generated_rake @include(if: ${this.hasField(fields, 'generated_rake')})
                ggr @include(if: ${this.hasAnyField(fields, this.getGGRQueryItems())}) {
                    mtt_service_fee @include(if: ${this.hasField(fields, 'ggr.mtt_service_fee')})
                    poker_rake @include(if: ${this.hasField(fields, 'ggr.poker_rake')})
                    poker_insurance @include(if: ${this.hasField(fields, 'ggr.poker_insurance')})
                    poker_features_purchase @include(if: ${this.hasField(fields, 'ggr.poker_features_purchase')})
                    poker_guess_hand @include(if: ${this.hasField(fields, 'ggr.poker_guess_hand')})
                    casino_poker_flips @include(if: ${this.hasField(fields, 'ggr.casino_poker_flips')})
                    casino_connector @include(if: ${this.hasField(fields, 'ggr.casino_connector')})
                }
                lifetime_bonus @include(if: ${this.hasField(fields, 'lifetime_bonus')})
                deposit @include(if: ${this.hasAnyField(fields, this.getDepositQueryItems())}) {
                    successful_amount @include(if: ${this.hasField(fields, 'deposit.successful_amount')})
                    failed_amount @include(if: ${this.hasField(fields, 'deposit.failed_amount')})
                    rejected_amount @include(if: ${this.hasField(fields, 'deposit.rejected_amount')})
                    successful_count @include(if: ${this.hasField(fields, 'deposit.successful_count')})
                    failed_count @include(if: ${this.hasField(fields, 'deposit.failed_count')})
                    rejected_count @include(if: ${this.hasField(fields, 'deposit.rejected_count')})
                    pending_amount @include(if: ${this.hasField(fields, 'deposit.pending_amount')})
                    pending_count @include(if: ${this.hasField(fields, 'deposit.pending_count')})
                    currency @include(if: ${this.hasField(fields, 'deposit.currency')})
                }
                withdrawal @include(if: ${this.hasAnyField(fields, this.getWithdrawalQueryItems())}) {
                    successful_amount @include(if: ${this.hasField(fields, 'withdrawal.successful_amount')})
                    failed_amount @include(if: ${this.hasField(fields, 'withdrawal.failed_amount')})
                    rejected_amount @include(if: ${this.hasField(fields, 'withdrawal.rejected_amount')})
                    successful_count @include(if: ${this.hasField(fields, 'withdrawal.successful_count')})
                    failed_count @include(if: ${this.hasField(fields, 'withdrawal.failed_count')})
                    rejected_count @include(if: ${this.hasField(fields, 'withdrawal.rejected_count')})
                    pending_amount @include(if: ${this.hasField(fields, 'withdrawal.pending_amount')})
                    pending_count @include(if: ${this.hasField(fields, 'withdrawal.pending_count')})
                    currency @include(if: ${this.hasField(fields, 'withdrawal.currency')})
                }
            }
        }
    `;

    protected buildFilter(filter: Filter<AggregatedFinanceSummaryFilterKeys>): QueryGetAggregatedFinanceSummaryArgs {
        return {
            filter: {
                uid: this.toGQLMultiselectFilter(filter, 'uid'),
                aggregation_range: this.toGQLDateRange(filter['aggregationRange.from'], filter['aggregationRange.to']),
            },
        };
    }

    protected buildPaging(_: Filter): {start: number; end: number} {
        return {start: undefined, end: undefined};
    }

    private getGGRQueryItems(): AggregatedFinanceSummaryQueryFields[] {
        return [
            'ggr.mtt_service_fee',
            'ggr.poker_rake',
            'ggr.poker_insurance',
            'ggr.poker_features_purchase',
            'ggr.poker_guess_hand',
            'ggr.casino_poker_flips',
            'ggr.casino_connector',
        ];
    }

    private getDepositQueryItems(): AggregatedFinanceSummaryQueryFields[] {
        return [
            'deposit.pending_amount',
            'deposit.pending_count',
            'deposit.successful_amount',
            'deposit.failed_amount',
            'deposit.rejected_amount',
            'deposit.successful_count',
            'deposit.failed_count',
            'deposit.rejected_count',
            'deposit.currency',
        ];
    }

    private getWithdrawalQueryItems(): AggregatedFinanceSummaryQueryFields[] {
        return [
            'withdrawal.pending_amount',
            'withdrawal.pending_count',
            'withdrawal.successful_amount',
            'withdrawal.failed_amount',
            'withdrawal.rejected_amount',
            'withdrawal.successful_count',
            'withdrawal.failed_count',
            'withdrawal.rejected_count',
            'withdrawal.currency',
        ];
    }
}
