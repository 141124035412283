import React, {useRef} from 'react';
import {defineMessages} from 'react-intl';
import {push} from '@vs-centaurea/connected-react-router';

import {DataGridColumn, DataGridServer, DataGridServerProps, TextColumn} from '@components/data-grid';
import {ActionButtonColumn, ActionButtonColumnValue} from '@components/data-grid/types';
import {LegalDocumentFilterKeys} from '@redux/entity';
import {formatDate} from '@utils';

import {RouteUrl} from 'src/common/routeEnums';
import {LegalDocumentViewModel, LegalDocumentViewModelKeys} from '../types';

import {localizedLegalDocumentDataGrid} from './DataGridLegalDocument.localize';

const localized = defineMessages({
    viewDocButtonLabel: {
        id: 'DataGridLegalDocument_viewDocButtonLabel',
        defaultMessage: 'View',
    },
});

export type LegalDocumentGridViewModelKeys = LegalDocumentViewModelKeys | 'view_document';
export type LegalDocumentGridViewModel = LegalDocumentViewModel;

class DataGridLegalDocumentConfigFactory {
    private readonly _config: Partial<Record<LegalDocumentGridViewModelKeys, DataGridColumn<LegalDocumentGridViewModel>>>;

    constructor() {
        const license_type: TextColumn<LegalDocumentGridViewModel> = {
            field: 'license_type',
            valueGetter: model => model?.licenseType,
            title: localizedLegalDocumentDataGrid.licenseType,
            sortable: false,
            width: 200,
            flex: 1,
            type: 'text',
        };
        const version: TextColumn<LegalDocumentGridViewModel> = {
            field: 'version',
            valueGetter: model => this.getNumberValue(model?.version),
            title: localizedLegalDocumentDataGrid.version,
            sortable: false,
            width: 200,
            flex: 1,
            type: 'text',
        };
        const actionTime: TextColumn<LegalDocumentGridViewModel> = {
            field: 'actionTime',
            valueGetter: model => this.getDateValue(model?.actionTime),
            title: localizedLegalDocumentDataGrid.actionTime,
            sortable: false,
            width: 150,
            flex: 1,
            type: 'text',
        };
        const viewDocumentAction: ActionButtonColumn<LegalDocumentGridViewModel> = {
            field: 'view_document',
            valueGetter: this.getViewDocumentCellValue,
            sortable: false,
            width: 75,
            flex: 0.5,
            type: 'action_button',
        };

        this._config = {
            licenseType: license_type,
            version: version,
            actionTime: actionTime,
            view_document: viewDocumentAction,
        };
    }

    public get config(): Partial<Record<LegalDocumentGridViewModelKeys, DataGridColumn<LegalDocumentGridViewModel>>> {
        return this._config;
    }

    private getNumberValue(value: number): string {
        return value?.toString();
    }

    private getDateValue(date: Date) {
        return formatDate(date, 'date-time');
    }

    private getViewDocumentCellValue(model: LegalDocumentGridViewModel): ActionButtonColumnValue {
        const licenseFilterKey: LegalDocumentFilterKeys = 'licenseType';
        const documentFilterKey: LegalDocumentFilterKeys = 'document';
        const query = `${licenseFilterKey}=${model?.licenseType}&${documentFilterKey}=${model?.type}`;
        return {label: localized.viewDocButtonLabel, action: push(`${RouteUrl.LegalDocumentDetails}?${query}`)};
    }
}

type DataGridLegalDocumentProps = Omit<
    DataGridServerProps<LegalDocumentGridViewModel, LegalDocumentGridViewModelKeys>,
    'type' | 'columns'
> & {
    columns: LegalDocumentGridViewModelKeys[];
};

export function DataGridLegalDocument(props: DataGridLegalDocumentProps) {
    const columns = useRef(getColumns(new DataGridLegalDocumentConfigFactory().config));

    function getColumns(
        config: Partial<Record<LegalDocumentGridViewModelKeys, DataGridColumn<LegalDocumentGridViewModel>>>
    ): DataGridColumn<LegalDocumentGridViewModel>[] {
        return props?.columns?.map((column: LegalDocumentGridViewModelKeys) => config[column]);
    }

    return <DataGridServer {...props} type="mui" columns={columns.current} />;
}
