import {Observable} from 'rxjs';

import {IModuleGridItem} from '@components/data-grid';
import {License} from '@models';
import {IItemDetailsReadService} from '@services/deprecated';

import {Jurisdiction} from '../../features/app/config/types';
import {IFormItem, IModuleItem} from '../../features/module-shared/types';

export const domain = {
    privacyPolicy: 'legal-content-management/privacy-policy',
    termsAndConditions: 'legal-content-management/terms-and-conditions',
    userAcceptanceVersion: 'legal-content-management/user-acceptance-version',
};

export enum LegalDocType {
    PrivacyPolicy = 'PrivacyPolicy',
    TermsAndConditions = 'TermsAndConditions',
}

export class LegalDocumentWrite implements IModuleItem {
    id: string;
    key: LegalDocumentWriteKey;
    required: boolean;
    document: string;
}

export class LegalDocumentWriteKey {
    name: string;
    jurisdiction: Jurisdiction;
}

export class LegalDocumentRead implements IModuleItem {
    id: string;
    version: number;
    document: string;
    actionTime: Date;
    author?: string;
    authorEmail?: string;
    required?: boolean;
    licenseType?: License;
}

export class LegalDocumentItem {
    name: string;
    version: number;
    acceptedAt: Date;
    content: string;
}

export class VersionAcceptanceGridItem implements IModuleGridItem {
    id: string;
    serverId: string;
    userId: string;
    email: string;
    documents: VersionAcceptanceDocument[];

    get isNew() {
        return false;
    }

    __typename = 'VersionAcceptance';
}

export class VersionAcceptanceDocument implements IModuleItem {
    id: string;
    jurisdiction: Jurisdiction;
    name: string;
    version: number;
    createdAt: Date;
    acceptedAt: Date;
    accepted: boolean;
    required: boolean;
    licenseType: License;
}

export interface ILegalDocsService extends IItemDetailsReadService<LegalDocumentRead> {
    addItem(item: IFormItem): Observable<IFormItem>;

    editItem(item: IModuleItem): Observable<string>;
}
