import React from 'react';
import {useSelector} from 'react-redux';

import {withPolicyAccessCheck} from '@access-control';
import {ErrorModal} from '@components/error';
import {LegalDocType} from '@models';
import {withTraceErrorBoundary} from '@otel';
import {LegalDocumentFilterKeys} from '@redux/entity';
import {getValueFromQueryFilter} from '@utils/query';

import {locationSearchSelector} from 'src/features/app/routing/selectors';
import {LegalDocumentDetails, privacyPolicyReadResource, termsAndConditionsReadResource} from 'src/features/block-legal-document';
import {FeatureName} from '../features/app/config/types';
import {LackOfPermissionIndicator, withPermissionAndJurisdictionConfig} from '../features/app/permission/PermissionHoc';

function TermsAndConditionsDetailsInner() {
    return <LegalDocumentDetails viewType="TermsAndConditionsDetails" documentType={LegalDocType.TermsAndConditions} />;
}

const TermsAndConditionsDetails = withPolicyAccessCheck(TermsAndConditionsDetailsInner, termsAndConditionsReadResource);

function PrivacyPolicyDetailsInner() {
    return <LegalDocumentDetails viewType="PrivacyPolicyDetails" documentType={LegalDocType.PrivacyPolicy} />;
}

const PrivacyPolicyDetails = withPolicyAccessCheck(PrivacyPolicyDetailsInner, privacyPolicyReadResource);

function LegalDocumentDetailsPage() {
    const queryString = useSelector(locationSearchSelector);
    const document = getValueFromQueryFilter<LegalDocumentFilterKeys, LegalDocType>(queryString, 'document');
    return document === LegalDocType.TermsAndConditions ? <TermsAndConditionsDetails /> : <PrivacyPolicyDetails />;
}

export default withTraceErrorBoundary(
    withPermissionAndJurisdictionConfig(
        LegalDocumentDetailsPage,
        {featureName: FeatureName.LegalDocumentWithLicense},
        LackOfPermissionIndicator.NotFound
    ),
    ErrorModal
);
