import {Country, EntityType} from '@redux/entity';
import {extendedViewCleanDelay, UseListViewEntityProps, useViewInit} from '@redux/view';
import {CountriesApiFilterKeys} from '@services/rest-api/countriesApiService';
import {CountryConfig} from '@brand';

import {useJurisdictionFeature} from '../app/config/hooks';
import {FeatureName} from '../app/config/types';

import {validateCountry} from './utils';

type UseCountriesResult = {
    countries: Country[];
};

export function useCountries({
    viewType,
    displayName,
    validateFilter,
    isCancelable,
    config,
}: Pick<UseListViewEntityProps<CountriesApiFilterKeys, keyof Country>, 'viewType' | 'displayName' | 'validateFilter' | 'isCancelable'> & {
    config?: CountryConfig;
}): UseCountriesResult {
    const entityType: EntityType = EntityType.Country;
    const {items} = useViewInit<Country, CountriesApiFilterKeys, keyof Country>({
        defaultFilters: [],
        viewType,
        displayName,
        entity: {
            entity: entityType,
            fields: ['iso2Code'],
        },
        validateFilter,
        cleanDelay: extendedViewCleanDelay,
        isCancelable,
    });

    const isLicenseTypeAvailable = useJurisdictionFeature({featureName: FeatureName.LicenseTypeDisplayAndEdit});

    function getCountries(): Country[] {
        return items?.filter(i => i).filter(i => (isLicenseTypeAvailable ? validateCountry(i.iso2Code, config) : true));
    }

    return {countries: getCountries()};
}
