import {CountryConfig} from '@brand';

export function validateCountry(iso2Code: string, countryConfig: CountryConfig): boolean {
    const isCountryIncluded = countryConfig?.iso2CodeList?.includes(iso2Code);
    return countryConfig?.mode === 'include' ? isCountryIncluded : !isCountryIncluded;
}

export function isStateRequired(iso2Code: string) {
    const countriesWithRequiredState = [
        'AR', // Argentina
        'BR', // Brazil
        'CA', // Canada
    ];
    return countriesWithRequiredState.includes(iso2Code);
}
