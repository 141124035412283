import React, {useContext} from 'react';
import {GridCellParams} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import {OutlinedButton} from '@components/button/Buttons';
import {ModalButtonColumnValue} from '@components/data-grid/types';
import {ModalContext} from '@components/modal/types';

const useStyles = makeStyles()(theme => ({
    muiGridModalButtonCell: {
        width: '80px',
        height: '32px',
        borderRadius: theme.shape.borderRadius * 2,
    },
}));

export function renderMuiGridModalButtonCell(params: GridCellParams) {
    const {openModal} = useContext(ModalContext);
    const {classes} = useStyles();
    const {label, modalContent} = params.value as ModalButtonColumnValue;
    return <OutlinedButton label={label} onClick={() => openModal(modalContent)} className={classes.muiGridModalButtonCell} />;
}
