import type {FormatNumberOptions, MessageDescriptor} from 'react-intl';
import type {Dispatch} from 'redux';

import {ColumnTypes, CurrencyColumn, DataGridColumn, IModuleGridItem, LinkColumn, LocalizedTextColumn, TextColumn} from '../types';

import {GlideCell, GlideCurrencyCell, GlideLinkCell, GlideLocalizedTextCell, GlideTextCell} from './cells';
import type {GlideGridColumn} from './types';

export class GlideGridColumnsConverter<TModel extends IModuleGridItem> {
    private readonly _formatMessage: (message: MessageDescriptor) => string;
    private readonly _formatNumber: (value: number, options: FormatNumberOptions) => string;
    private readonly _dispatch: Dispatch;

    constructor(
        formatMessage: (message: MessageDescriptor) => string,
        formatNumber: (value: number, options: FormatNumberOptions) => string,
        dispatch: Dispatch
    ) {
        this._formatMessage = formatMessage;
        this._formatNumber = formatNumber;
        this._dispatch = dispatch;
    }

    public convert(columns: DataGridColumn<TModel>[], visibleColumns: string[]): GlideGridColumn[] {
        return columns
            .filter(c => visibleColumns === null || visibleColumns === undefined || visibleColumns.includes(c.field))
            .map<GlideGridColumn>(column => ({
                id: column.field,
                title: '',
                columnName: this._formatMessage(column.title),
                sortable: column.sortable,
                cell: this.getDataCell(column),
                width: column.width,
            }));
    }

    private getDataCell(column: DataGridColumn<TModel>): GlideCell {
        const columnMap: Record<ColumnTypes, () => GlideCell> = {
            text: () => new GlideTextCell((column as TextColumn<TModel>).valueGetter),
            localized_text: () => new GlideLocalizedTextCell((column as LocalizedTextColumn<TModel>).valueGetter, this._formatMessage),
            link: () => new GlideLinkCell((column as LinkColumn<TModel>).valueGetter, this._dispatch),
            currency: () => new GlideCurrencyCell((column as CurrencyColumn<TModel>).valueGetter, this._formatNumber),
            action_button: () => {
                throw Error('cell type not implemented');
            },
            modal_button: () => {
                throw Error('cell type not implemented');
            },
        };

        return columnMap[column.type]();
    }
}
