import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf, RootState} from 'typesafe-actions';

import {map, mergeMap} from '@otel';
import {RootEpic} from '@redux';
import {getFilterString, getSelectedItemString} from '@utils';

import {requestEpic} from 'src/common/epics';
import {ServiceContainer} from '../../app/inversify/serviceContainer';
import {protectEpics} from '../../features/app/error-handling/epics';
import {successMessages} from '../../features/app/intl';
import {handleErrorResponseAction, showMessageAction} from '../../features/message-snack-bar/actions';
import {contentModuleActions} from '../../features/module-shared/actions';
import {createGridEpics, createSingleItemReadEpics} from '../../features/module-shared/epics';

import {legalModuleActions, userAcceptanceVersionActions} from './actions';
import {filterSelector} from './selectors';
import {domain, ILegalDocsService, LegalDocumentRead, VersionAcceptanceDocument, VersionAcceptanceGridItem} from './types';
import {getLegalDocId, getLegalDocPath, getLegalDocQueryKeyByDocType} from './utils';

const createContentModuleWithHistoryEpics = (
    domain: string,
    serviceResolver: (container: ServiceContainer) => ILegalDocsService,
    filterSelector: (state: RootState) => string
) => {
    const actions = contentModuleActions(domain);

    const itemSaveEpic: RootEpic = requestEpic(actions.itemSave, (payload, _, container) => {
        const service = serviceResolver(container);
        return payload.isNew ? service.addItem(payload.item) : service.editItem(payload.item);
    });

    const itemSavedEpic: RootEpic = action$ =>
        action$.pipe(
            filter(isActionOf(actions.itemSave.success)),
            mergeMap(() => of(showMessageAction({message: successMessages.dataSaved}), actions.contentUpdateRequired()))
        );

    const requestFailureEpic: RootEpic = action$ =>
        action$.pipe(
            filter(isActionOf([actions.itemSave.failure])),
            map(action => handleErrorResponseAction(action.payload))
        );

    return protectEpics(
        createSingleItemReadEpics<LegalDocumentRead>(domain, serviceResolver, filterSelector),
        itemSaveEpic,
        itemSavedEpic,
        requestFailureEpic
    );
};

const createLegalDocumentModuleEpics = (
    domain: string,
    serviceResolver: (container: ServiceContainer) => ILegalDocsService,
    filterSelector: (state: RootState) => string
) => {
    const actions = legalModuleActions(domain);

    const initLegalDocsModuleEpic: RootEpic = action$ =>
        action$.pipe(
            filter(isActionOf(actions.initLegalDocsModule)),
            mergeMap(action => {
                const docType = action.payload;
                const jurisdiction = action.meta;

                const filter = getLegalDocQueryKeyByDocType(docType, jurisdiction, getLegalDocPath);
                const filterString = getFilterString(filter);

                const contentId = getLegalDocQueryKeyByDocType(docType, jurisdiction, getLegalDocId);
                const updatedFilterString = getSelectedItemString(filterString, contentId);

                return of(actions.setFilter(getFilterString(updatedFilterString)));
            })
        );

    return protectEpics(initLegalDocsModuleEpic, createContentModuleWithHistoryEpics(domain, serviceResolver, filterSelector));
};

const privacyPolicyEpics = createLegalDocumentModuleEpics(
    domain.privacyPolicy,
    c => c.legalDocumentService,
    (state: RootState) => state.privacyPolicy.data.state.filter
);

const termsAndConditionsEpics = createLegalDocumentModuleEpics(
    domain.termsAndConditions,
    c => c.legalDocumentService,
    (state: RootState) => state.termsAndConditions.data.state.filter
);

const userAcceptanceDocumentLoadEpic: RootEpic = requestEpic(userAcceptanceVersionActions.loadDocument, (payload, _, container) =>
    container.userAcceptanceVersionService.getItem(getLegalDocId(payload.jurisdiction, payload.name, payload.version, payload.licenseType))
);

const userAcceptanceDocumentLoadFailureEpic: RootEpic = action$ =>
    action$.pipe(
        filter(isActionOf(userAcceptanceVersionActions.loadDocument.failure)),
        map(action => handleErrorResponseAction(action.payload))
    );

const userAcceptanceVersionEpics = protectEpics(
    userAcceptanceDocumentLoadEpic,
    userAcceptanceDocumentLoadFailureEpic,
    createGridEpics<VersionAcceptanceDocument, VersionAcceptanceGridItem>(
        domain.userAcceptanceVersion,
        c => c.userAcceptanceVersionService,
        filterSelector
    )
);

export default protectEpics(privacyPolicyEpics, termsAndConditionsEpics, userAcceptanceVersionEpics);
