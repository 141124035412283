import {LegalDocType, License} from '@models';

export class LegalDocumentViewModel {
    id: string;
    actionTime: Date;
    author: string;
    document: string;
    version: number;
    licenseType: License;
    required: boolean;
    type: LegalDocType;
}

export type LegalDocumentViewModelKeys = keyof LegalDocumentViewModel;

export type LegalDocumentSortingFields = LegalDocumentViewModelKeys;
