import React from 'react';

import {withPolicyAccessCheck} from '@access-control';
import {ErrorModal} from '@components/error';
import {LegalDocType} from '@models';
import {withTraceErrorBoundary} from '@otel';

import {useJurisdictionFeature} from '../features/app/config/hooks';
import {FeatureName} from '../features/app/config/types';
import {LegalDocumentQueue, privacyPolicyReadResource} from '../features/block-legal-document';

import PrivacyPolicyObsolete from './legal-docs/components/PrivacyPolicy';

function PrivacyPolicy() {
    return (
        <LegalDocumentQueue
            document={LegalDocType.PrivacyPolicy}
            viewType="PrivacyPolicy"
            fields={['licenseType', 'version', 'actionTime']}
        />
    );
}

function PrivacyPolicyInner() {
    const licenseFeatureAvailable = useJurisdictionFeature({
        featureName: FeatureName.LegalDocumentWithLicense,
    });

    return licenseFeatureAvailable ? <PrivacyPolicy /> : <PrivacyPolicyObsolete />;
}

export default withTraceErrorBoundary(withPolicyAccessCheck(PrivacyPolicyInner, privacyPolicyReadResource), ErrorModal);
