import React, {FC} from 'react';
import {defineMessages} from 'react-intl';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import {ButtonGroup} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomIcon} from '@components/icons';

import Button, {IconButton, SquareIconButton} from './Buttons';

export type EditMode = 'Read' | 'Edit' | 'EditMultiStep' | 'InProgress';

export type EditButtonProps = {
    mode?: EditMode;
    disabled?: boolean;
    disabledSubmitButton?: boolean;
    onEdit: () => void;
    onSave: () => void;
    onCancel: () => void;
    onNextStep?: () => void;
};

const useClasses = makeStyles()(theme => ({
    placement: {
        alignSelf: 'flex-start',
    },
    editModalButton: {
        height: theme.spacing(4),
    },
}));

const localized = defineMessages({
    editModalButtonNextLabel: {
        id: 'EditModalButton_editModalButtonNextLabel',
        defaultMessage: 'Next',
    },
});

export const EditButton: FC<EditButtonProps> = ({
    mode = 'Read',
    disabled = false,
    disabledSubmitButton,
    onEdit,
    onCancel,
    onSave,
    onNextStep,
}) => {
    const {classes} = useClasses();

    switch (mode) {
        case 'Read':
            return (
                <IconButton
                    iconClassName={`${CustomIcon.WriteOutline}`}
                    onClick={onEdit}
                    data-testid="editButton"
                    buttonSmall={true}
                    disabled={disabled}
                ></IconButton>
            );
        case 'Edit': {
            return (
                <ButtonGroup aria-label="outlined primary button group" className={classes.placement}>
                    <SquareIconButton
                        type="submit"
                        iconClassName={CustomIcon.Confirm}
                        buttonSmall={true}
                        data-testid="submitButton"
                        onSubmit={onSave}
                        disabled={disabled || disabledSubmitButton}
                    >
                        <DoneIcon />
                    </SquareIconButton>
                    <SquareIconButton
                        iconClassName={CustomIcon.CancelOutline}
                        type="button"
                        buttonSmall={true}
                        onClick={onCancel}
                        data-testid="stopEditButton"
                        disabled={disabled}
                    >
                        <ClearIcon />
                    </SquareIconButton>
                </ButtonGroup>
            );
        }
        case 'EditMultiStep': {
            return (
                <Button
                    className={classes.editModalButton}
                    color="primary"
                    size="small"
                    label={localized.editModalButtonNextLabel}
                    onClick={onNextStep}
                    disabled={disabled}
                />
            );
        }
        case 'InProgress':
            return <IconButton iconClassName={CustomIcon.Union} buttonSmall={true} disabled className={classes.placement}></IconButton>;
        default:
            return null;
    }
};
