import {useAutoMapper} from '@auto-mapper';
import {License} from '@models';
import {EntityType, LegalDocumentFilterKeys} from '@redux/entity';
import {UseListViewEntityProps, UseListViewEntityResult, useViewInit} from '@redux/view';
import {LegalDocument} from '@services/rest-api/legalDocumentApiService';
import {updateKeyValueFilterArray} from '@utils';

import {Filter, Paging, Sorting} from 'src/common/types';

import {LegalDocumentSortingFields, LegalDocumentViewModel, LegalDocumentViewModelKeys} from './types';

export type LegalDocumentKeyValueFilter = Filter<unknown, LegalDocumentFilterKeys>;

export function useLegalDocuments({
    viewType,
    fields,
    displayName,
    cleanDelay = 0,
    defaultFilters,
    defaultPaging,
    defaultSorting,
    validateFilter,
    syncWithUrl,
}: UseListViewEntityProps<LegalDocumentFilterKeys, LegalDocumentViewModelKeys>): UseListViewEntityResult<
    LegalDocumentViewModel,
    LegalDocumentViewModelKeys
> {
    const mapper = useAutoMapper();

    const {
        items,
        totalCount,
        searchFilter,
        viewEntity: {filter: filterString},
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
        forceViewUpdate,
    } = useViewInit<LegalDocument, LegalDocumentFilterKeys, string, LegalDocumentSortingFields>({
        viewType,
        displayName,
        entity: {
            entity: EntityType.LegalDocument,
            fields,
        },
        defaultFilters: getDefaultFilters(defaultFilters),
        defaultSorting: mapSorting(defaultSorting),
        defaultPaging: getDefaultPaging(defaultPaging, defaultFilters),
        syncWithUrl,
        validateFilter,
        cleanDelay,
    });

    const documents = items?.map(i => mapper.map(i, LegalDocument, LegalDocumentViewModel));

    function handleVmSortChange(newSorting: Sorting<LegalDocumentViewModelKeys>[]) {
        handleSortChange(mapSorting(newSorting));
    }

    function getDefaultFilters(filters: LegalDocumentKeyValueFilter[]): LegalDocumentKeyValueFilter[] {
        const defaultFilters: LegalDocumentKeyValueFilter[] = [{key: 'licenseType', value: Object.values(License)}];
        return updateKeyValueFilterArray(defaultFilters, ...(filters ?? []));
    }

    function getDefaultPaging(paging: Paging, filters: LegalDocumentKeyValueFilter[]): Paging {
        const licenseFilterKey: LegalDocumentFilterKeys = 'licenseType';
        const licenseFilterValue: License[] = filters?.find(f => f.key === licenseFilterKey)?.value as License[];
        return paging || licenseFilterValue?.length ? paging : {page: 1, pageSize: Object.values(License)?.length};
    }

    function mapSortField(field: LegalDocumentViewModelKeys): LegalDocumentSortingFields {
        return mapper.map<LegalDocumentViewModelKeys, LegalDocumentSortingFields>(
            field,
            nameof<LegalDocumentViewModelKeys>(),
            nameof<LegalDocumentSortingFields>()
        );
    }

    function mapSorting(newSorting: Sorting<LegalDocumentViewModelKeys>[]): Sorting<LegalDocumentSortingFields>[] {
        return newSorting?.map(s => ({...s, field: mapSortField(s.field)}));
    }

    return {
        items: documents,
        totalCount,
        searchFilter,
        filterString,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange: handleVmSortChange,
        handleFilterChange,
        forceViewUpdate,
    };
}
