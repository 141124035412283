import {inject, injectable} from 'inversify';
import {forkJoin, Observable} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {LegalDocType, License} from '@models';
import {map} from '@otel';
import {EntityFetchRequestPayload, EntityFetchServiceResponsePayload, Filter, LegalDocumentFilterKeys} from '@redux/entity';
import {IEntityReadService} from '@services/entity';
import {BaseRequestBuilder} from '@services/entity/BaseRequestBuilder';
import {LegalDocumentApiService} from '@services/rest-api';
import {
    GetLegalDocumentRequest,
    GetLegalDocumentResponse,
    LegalDocument,
    LegalDocumentApiFilterKeys,
    LegalDocumentEditModel,
} from '@services/rest-api/legalDocumentApiService';
import {HttpStatusCode, ServerResponseStatus} from '@services/types';
import {getValueFromQueryFilter} from '@utils/query';

@injectable()
export class LegalDocumentService implements IEntityReadService {
    private readonly _apiService: LegalDocumentApiService;
    private readonly _builder: LegalDocumentRequestBuilder;

    constructor(@inject(ServiceTypes.LegalDocumentApiService) apiService: LegalDocumentApiService) {
        this._apiService = apiService;
        this._builder = new LegalDocumentRequestBuilder();
    }

    public get(requestPayload: EntityFetchRequestPayload): Observable<EntityFetchServiceResponsePayload<LegalDocument>> {
        const requests: GetLegalDocumentRequest[] = this._builder.buildRequest(requestPayload);
        const requests$: Observable<GetLegalDocumentResponse>[] = requests?.map(request => this._apiService.get(request));
        return forkJoin(requests$).pipe(
            map(responses => {
                let result: EntityFetchServiceResponsePayload<LegalDocument>;
                const checkedResponses = this.replaceNotFoundResponsesToEmptyDocs(responses);
                const failedRequest = checkedResponses?.find(r => r?.status !== ServerResponseStatus.Success);
                if (failedRequest) {
                    result = {...failedRequest, requestPayload, responsePayload: null};
                } else {
                    const licenseFilterValue = getValueFromQueryFilter<LegalDocumentFilterKeys>(requestPayload.filter, 'licenseType');
                    const items = checkedResponses?.flatMap(r => r?.responsePayload);
                    result = {
                        status: ServerResponseStatus.Success,
                        requestPayload,
                        responsePayload: {items, total: licenseFilterValue ? items?.length : Object.values(License)?.length},
                    };
                }
                return result;
            })
        );
    }

    public edit(model: LegalDocumentEditModel) {
        return this._apiService.edit(model);
    }

    private replaceNotFoundResponsesToEmptyDocs(responses: GetLegalDocumentResponse[]): GetLegalDocumentResponse[] {
        return responses?.map(response =>
            response?.errors?.[0]?.statusCode === HttpStatusCode.NotFound ? this.getEmptyDocumentResponse(response) : response
        );
    }

    private getEmptyDocumentResponse(response: GetLegalDocumentResponse): GetLegalDocumentResponse {
        const request = response?.requestPayload;
        const licenseType = getValueFromQueryFilter<LegalDocumentApiFilterKeys, License>(request?.query, 'licenseType');
        return {
            status: ServerResponseStatus.Success,
            requestPayload: request,
            responsePayload: {
                isDeleted: false,
                actionTime: null,
                author: null,
                document: null,
                required: false,
                version: null,
                licenseType: licenseType,
                type: request?.document,
            },
        };
    }
}

export class LegalDocumentRequestBuilder extends BaseRequestBuilder<LegalDocumentFilterKeys> {
    private readonly _defaultDocumentType: LegalDocType = LegalDocType.PrivacyPolicy;

    public buildRequest(requestPayload: EntityFetchRequestPayload): GetLegalDocumentRequest[] {
        const filterObject = this.mapToFilterObject(requestPayload.filter);

        const licenses: License[] = this.getLicenseList(filterObject);
        const document: LegalDocType = (filterObject.document as LegalDocType) ?? this._defaultDocumentType;
        const version: number = this.toNumberFilter(filterObject, 'version');

        const requests = licenses.map(license => this.getRequest(document, license, version));
        const result = this.clientPagination(requests, filterObject);
        return result;
    }

    private getLicenseList(filter: Filter<LegalDocumentFilterKeys>): License[] {
        const licenseFilterValue = this.toMultiselectFilter<License[]>(filter, 'licenseType');
        return licenseFilterValue
            ? Array.isArray(licenseFilterValue)
                ? licenseFilterValue
                : [licenseFilterValue]
            : Object.values(License);
    }

    private getRequest(document: LegalDocType, license: License, version: number): GetLegalDocumentRequest {
        const searchParams = new URLSearchParams('');
        const licenseKey: LegalDocumentApiFilterKeys = 'licenseType';
        const versionKey: LegalDocumentApiFilterKeys = 'version';
        if (license) {
            searchParams.set(licenseKey, license);
        }
        if (version) {
            searchParams.set(versionKey, `${version}`);
        }
        return {document, query: searchParams?.toString()};
    }

    private clientPagination(items: GetLegalDocumentRequest[], filter: Filter) {
        const {start, end} = this.buildPaging({page: this.toNumberFilter(filter, 'page'), size: this.toNumberFilter(filter, 'size')});
        return items.slice(start, end);
    }
}
