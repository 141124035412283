import {constructUsing, createMap, forMember, mapFrom, Mapper} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {LegalManagementHistory} from '@models/generated/businessPoolGraphql';
import {LegalDocHistoryQueryFields} from '@redux/entity';
import {IsNever} from '@utils/type';

import {LegalDocHistorySortingFields, LegalDocHistoryViewModel, LegalDocHistoryViewModelKeys} from './types';

export class LegalDocHistoryMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            LegalManagementHistory,
            LegalDocHistoryViewModel,
            forMember(
                vm => vm.id,
                mapFrom(m => `${m.document}_${m.license_type}_${m.version}`)
            ),
            forMember(
                vm => vm.user_id,
                mapFrom(m => m.user_id)
            ),
            forMember(
                vm => vm.version,
                mapFrom(m => m.version)
            ),
            forMember(
                vm => vm.created_at,
                mapFrom(m => m.created_at)
            ),
            forMember(
                vm => vm.ip,
                mapFrom(m => m.ip)
            ),
            forMember(
                vm => vm.document,
                mapFrom(m => m.document)
            ),
            forMember(
                vm => vm.license_type,
                mapFrom(m => m.license_type)
            )
        );
        createMap<LegalDocHistoryViewModelKeys, LegalDocHistorySortingFields>(
            mapper,
            nameof<LegalDocHistoryViewModelKeys>(),
            nameof<LegalDocHistorySortingFields>(),
            constructUsing(m => {
                type FieldsToMap = Exclude<LegalDocHistoryViewModelKeys, LegalDocHistorySortingFields>;
                const isNever: IsNever<FieldsToMap> = true;
                if (!isNever) {
                    throw new Error('Mapping should be defined for all values');
                }
                return m;
            })
        );
        createMap<LegalDocHistoryViewModelKeys[], LegalDocHistoryQueryFields[]>(
            mapper,
            nameof<LegalDocHistoryViewModelKeys>(),
            nameof<LegalDocHistoryQueryFields>(),
            constructUsing((m, _) => {
                const mapper: Record<LegalDocHistoryViewModelKeys, LegalDocHistoryQueryFields> = {
                    id: null,
                    user_id: 'items.user_id',
                    version: 'items.version',
                    created_at: 'items.created_at',
                    ip: 'items.ip',
                    document: 'items.document',
                    license_type: 'items.license_type',
                    user_email: null,
                    type: 'items.document',
                };
                const requiredQueryFields: LegalDocHistoryQueryFields[] = [
                    'items.document',
                    'items.license_type',
                    'items.version',
                    'total_count',
                ];
                const itemsQueryFields = m?.map(f => mapper[f])?.filter(i => i);

                return [...new Set([...itemsQueryFields, ...requiredQueryFields])];
            })
        );
    }
}
