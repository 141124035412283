import React, {useEffect, useState} from 'react';
import {MessageDescriptor} from 'react-intl';
import {Box} from '@mui/material';

import {AutocompleteOptionType} from '@components/autocomplete';
import {EditableProps, withEditButton} from '@components/editable/EditButtonHoc';
import {ServerLicenseType} from '@redux/entity/types/entityUserProfile';

import {EditableAutocompleteProps} from './EditableAutocomplete';
import {useMultipleEditableAutocompleteClasses} from './MultipleEditableAutocomplete.style';

export type MultiAutocompleteValueType = {
    [key: string]: AutocompleteOptionType;
};

type AutocompleteModel<TFilter> = {
    key: string;
    filter?: TFilter;
    errorMessage?: string;
};

export type MultipleEditableAutocompleteProps<
    TFilter extends Partial<Record<string, unknown>>,
    TModel extends MultiAutocompleteValueType
> = {
    value: TModel;
    isDisabled?: boolean;
    onChange: (value: TModel) => void;
    autocompletes?: AutocompleteModel<TFilter>[];
    placeholder?: MessageDescriptor;
    license?: ServerLicenseType;
};

/**
 * @deprecated Should be removed
 */
export const withMultipleEditableAutocomplete = <
    TFilter extends Partial<Record<string, unknown>>,
    TModel extends MultiAutocompleteValueType
>(
    ...WrappedComponents: React.ComponentType<EditableAutocompleteProps<TFilter> & {license?: ServerLicenseType}>[]
) =>
    withEditButton(
        ({mode, autocompletes, value, onChange, license, ...props}: EditableProps & MultipleEditableAutocompleteProps<TFilter, TModel>) => {
            const {classes, cx} = useMultipleEditableAutocompleteClasses();
            const {disabled} = props;
            const isEditDisabled = disabled || mode !== 'Edit';
            const [inputValues, setInputValues] = useState(value);

            useEffect(() => {
                setInputValues(value);
            }, [JSON.stringify(value)]);

            const handleChange = (k: string, v: AutocompleteOptionType) => {
                const newValue = {...inputValues, [k]: v};
                setInputValues(newValue);
                onChange(newValue);
            };

            return (
                <Box
                    className={cx(classes.multipleEditableAutocompleteContainer, {
                        [classes.multipleEditableAutocompleteContainerDisabled]: isEditDisabled,
                    })}
                >
                    {WrappedComponents.map((Component, index) => {
                        const {key, filter, errorMessage} = autocompletes[index];
                        const isLast = index === WrappedComponents.length - 1;
                        return (
                            <React.Fragment key={key}>
                                <Component
                                    {...props}
                                    license={license}
                                    value={value[key]?.value}
                                    label={value[key]?.label}
                                    isDisabled={isEditDisabled}
                                    filter={filter}
                                    errorMessage={errorMessage}
                                    onOptionChange={v => handleChange(key, v)}
                                />
                                {!isLast && isEditDisabled ? ', ' : null}
                            </React.Fragment>
                        );
                    })}
                </Box>
            );
        }
    );
