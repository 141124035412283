import {defineMessages} from 'react-intl';
import {inject, injectable} from 'inversify';
import {Epic} from 'redux-observable';
import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';

import {ServiceTypes} from '@inversify';
import {map, mergeMap} from '@otel';
import {BaseEpicsBuilder} from '@redux';
import {LegalDocumentService} from '@services';
import {LegalDocumentEditModel} from '@services/rest-api/legalDocumentApiService';

import {showErrorAction, showMessageAction} from '../message-snack-bar/actions';

import {legalDocumentActions} from './actions';

const localized = defineMessages({
    successEditMessage: {
        id: 'LegalDocumentEpicsBuilder_successEditMessage',
        defaultMessage: 'Document was updated',
    },
    failedEditMessage: {
        id: 'LegalDocumentEpicsBuilder_failedEditMessage',
        defaultMessage: 'Failed attempt to update the document. Try again later',
    },
});

@injectable()
export class LegalDocumentEpicsBuilder extends BaseEpicsBuilder {
    private readonly _legalDocumentService: LegalDocumentService;

    constructor(@inject(ServiceTypes.LegalDocumentService) legalDocumentService: LegalDocumentService) {
        super();
        this._legalDocumentService = legalDocumentService;
    }

    protected buildEpicList(): Epic[] {
        return [this.buildLegalDocumentRequestEpic(), this.buildLegalDocumentSuccessEpic(), this.buildLegalDocumentFailureEpic()];
    }

    private buildLegalDocumentRequestEpic(): Epic {
        return this.buildRequestEpic<LegalDocumentEditModel, LegalDocumentEditModel, null>(
            legalDocumentActions.editLegalDocument,
            payload => this._legalDocumentService.edit(payload)
        );
    }

    private buildLegalDocumentSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(legalDocumentActions.editLegalDocument.success)),
                mergeMap(() => {
                    return of(showMessageAction({message: localized.successEditMessage}));
                })
            );
    }

    private buildLegalDocumentFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(legalDocumentActions.editLegalDocument.failure)),
                map(() => showErrorAction({message: localized.failedEditMessage}))
            );
    }
}
