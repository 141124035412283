import {inject, injectable} from 'inversify';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {baseFilterKeys, Country, State} from '@redux/entity';
import {ApiRoutesService} from '@services/rest-api/apiRoutesService';
import {HttpStatusCode, ServerResponseStatus, ServiceResponsePayload} from '@services/types';

import {RestService} from './restService';

export const countryFilterKey = 'country' as const;

export const countriesStateFilterKeys = [...baseFilterKeys, countryFilterKey];
export type CountriesApiFilterKeys = (typeof countriesStateFilterKeys)[number];

@injectable()
export class CountriesApiService {
    private readonly _httpService: RestService;
    private readonly _apiRouteService: ApiRoutesService;

    constructor(
        @inject(ServiceTypes.RestFetchApiService) httpService: RestService,
        @inject(ServiceTypes.ApiRoutesService) apiRouteService: ApiRoutesService
    ) {
        this._httpService = httpService;
        this._apiRouteService = apiRouteService;
    }

    public getCountries(): Observable<ServiceResponsePayload<unknown, Country[]>> {
        const endpoint = this._apiRouteService?.getCountriesEndpoint();
        return this._httpService.get({endpoint});
    }

    public getStates(countryIsoCode: string): Observable<ServiceResponsePayload<unknown, State[]>> {
        if (countryIsoCode && countryIsoCode !== 'undefined') {
            const endpoint = this._apiRouteService?.getStatesEndpoint(countryIsoCode);
            return this._httpService.get({endpoint}).pipe(
                map(response => {
                    const responseStatus =
                        response?.errors?.length === 1 && response.errors[0].code === HttpStatusCode.NotFound.toString()
                            ? ServerResponseStatus.Success
                            : response.status;

                    return {
                        ...response,
                        status: responseStatus,
                        responsePayload: response?.responsePayload?.subdivisions,
                    };
                })
            );
        }

        return of();
    }
}
