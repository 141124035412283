export enum License {
    Curacao = 'Curacao',
    Tobique = 'Tobique',
    Mexico = 'Mexico',
    Philippines = 'Philippines',
    SouthKorea = 'SouthKorea',
}

export enum LegalDocType {
    PrivacyPolicy = 'PrivacyPolicy',
    TermsAndConditions = 'TermsAndConditions',
}
