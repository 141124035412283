import {NormalizedCacheObject} from '@apollo/client';
import {inject, injectable} from 'inversify';
import {merge, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {EntityType} from '@redux/entity';
import {RealtimeMessageTrigger} from '@redux/realtime';
import {
    AccountVerificationSubscription,
    BackpackSubscription,
    BonusCodeSubscription,
    BotGroupSubscription,
    BulkOperationSubscription,
    EntitySubscription,
    EventLogRecordSubscription,
    GeoCheckSummarySubscription,
    LabelGroupSubscription,
    MarketingCodeSubscription,
    NotesSubscription,
    PageViewSubscription,
    PlayerBonusSubscription,
    PlayerReferralSubscription,
    PlayerReferrerSummarySubscription,
    PlayersRelationSubscription,
    ReferralSettingsSubscription,
    TransactionSubscription,
    UserActionSubscription,
    UserLoginSubscription,
    UserProfileSubscription,
} from '@services/entity/EntitySubscription';

import {GqlSubscriptionResponsePayload} from './gql-api/gqlService';
import {ApolloClientProxy, GraphQLService} from './gql-api';
import {ServerResponseStatus, ServiceResponsePayload} from './types';

export type RealtimeEntitySubscriptionPayload = {
    entity: EntityType;
    triggers: {type: RealtimeMessageTrigger; args?: unknown; key: string}[];
};
export type RealtimeServiceSubscribeResponsePayload = ServiceResponsePayload<RealtimeEntitySubscriptionPayload, unknown[]>;
export type RealtimeServiceUnsubscribeResponsePayload = ServiceResponsePayload<RealtimeEntitySubscriptionPayload, void>;

export interface IRealtimeService {
    subscribe(payload: RealtimeEntitySubscriptionPayload): Observable<RealtimeServiceSubscribeResponsePayload>;

    unsubscribe(payload: RealtimeEntitySubscriptionPayload): Observable<RealtimeServiceUnsubscribeResponsePayload>;
}

@injectable()
export class RealtimeService implements IRealtimeService {
    private readonly _subscriptionMappings: Record<EntityType, EntitySubscription> = {
        AgentPathStatistics: undefined,
        UserProfile: new UserProfileSubscription(),
        UserLogin: new UserLoginSubscription(),
        Transaction: new TransactionSubscription(),
        KycCheckResults: undefined,
        RgUserLimits: undefined,
        UserAction: new UserActionSubscription(),
        Note: new NotesSubscription(),
        BotGroup: new BotGroupSubscription(),
        UserStatistics: undefined,
        FriendGroup: undefined,
        PlayersRelation: new PlayersRelationSubscription(),
        SecurityCase: undefined,
        AccountVerification: new AccountVerificationSubscription(),
        MarketingCode: new MarketingCodeSubscription(),
        PlayerBonus: new PlayerBonusSubscription(),
        PlayerReferral: new PlayerReferralSubscription(),
        PlayerReferrerSummary: new PlayerReferrerSummarySubscription(),
        BoUser: undefined,
        BoRole: undefined,
        BoModule: undefined,
        LegalDocAcceptanceVersion: undefined,
        Country: undefined,
        State: undefined,
        LabelGroup: new LabelGroupSubscription(),
        Occupation: undefined,
        ReferralSettings: new ReferralSettingsSubscription(),
        BulkOperation: new BulkOperationSubscription(),
        EventLogRecord: new EventLogRecordSubscription(),
        GeoCheckSummary: new GeoCheckSummarySubscription(),
        AggregatedFinanceSummary: undefined,
        AgentPlayerReporting: undefined,
        AgentProfile: undefined,
        Backpack: new BackpackSubscription(),
        GameTemplate: undefined,
        GameRoom: undefined,
        GameBlind: undefined,
        BonusCode: new BonusCodeSubscription(),
        Rule: undefined,
        CasinoGame: undefined,
        CasinoSupplier: undefined,
        AgentLevelReport: undefined,
        SportsbookTransaction: undefined,
        EmbeddedReport: undefined,
        PageView: new PageViewSubscription(),
        UserActivitySummary: undefined,
        BoUserSettings: undefined,
        LegalDocument: undefined,
        LegalDocHistory: undefined,
    };

    private readonly _client: GraphQLService;
    private readonly _activeSubscriptions: Record<string, Observable<RealtimeServiceSubscribeResponsePayload>> = {};

    constructor(@inject(ServiceTypes.ApolloClientIGP) client: ApolloClientProxy<NormalizedCacheObject>) {
        this._client = new GraphQLService(client);
    }

    public subscribe(payload: RealtimeEntitySubscriptionPayload): Observable<RealtimeServiceSubscribeResponsePayload> {
        const subscriptionFactory = this._subscriptionMappings[payload.entity];

        for (const {type, args, key} of payload.triggers) {
            let subscriber = this._activeSubscriptions[key];

            if (!subscriber) {
                const request = subscriptionFactory?.getSubscription(type);

                if (request) {
                    subscriber = this._client.subscribeTemp(request, key, args).pipe(
                        map<GqlSubscriptionResponsePayload, RealtimeServiceSubscribeResponsePayload>(r => {
                            const mapResponse = subscriptionFactory.getResponseMapper(type);
                            return {
                                ...r,
                                requestPayload: {...payload, triggers: [{type, args, key}]},
                                responsePayload: mapResponse && r.responsePayload ? mapResponse(r.responsePayload) : [],
                            };
                        })
                    );

                    this._activeSubscriptions[key] = subscriber;
                } else {
                    //subscription does not exists, ignore it
                }
            }
        }

        return merge(...this.getSubscriptionsArray());
    }

    public unsubscribe(payload: RealtimeEntitySubscriptionPayload): Observable<RealtimeServiceUnsubscribeResponsePayload> {
        const potentialUnsubscribersKeys = payload.triggers.map(trigger => trigger.key);

        potentialUnsubscribersKeys.forEach(key => {
            delete this._activeSubscriptions[key];
        });

        return this._client.unsubscribe(potentialUnsubscribersKeys).pipe(
            map(() => {
                return {
                    requestPayload: payload,
                    responsePayload: null,
                    status: ServerResponseStatus.Success,
                };
            })
        );
    }

    private getSubscriptionsArray(): Observable<RealtimeServiceSubscribeResponsePayload>[] {
        return Object.values(this._activeSubscriptions);
    }
}
