import {constructUsing, createMap, forMember, mapFrom, Mapper, mapWith} from '@automapper/core';
import produce from 'immer';

import {IMapping} from '@auto-mapper';
import {AgentInfo, SecurityCase, UserProfile} from '@models/generated/graphql';
import {UserProfileQueryFields} from '@redux/entity';
import {ServerLicenseType} from '@redux/entity/types/entityUserProfile';
import {LicenseType} from '@services/brandService';

import {SecurityCaseViewModel} from 'src/features/block-security-case';

import {
    AgentInfoViewModel,
    AgentPokerCashViewModel,
    AgentRevenueShareViewModel,
    UserProfileColumnSettingsKeys,
    UserProfileViewModel,
    UserProfileViewModelKeys,
} from './types';

export class UserProfileMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap<UserProfileViewModelKeys[], UserProfileQueryFields[]>(
            mapper,
            nameof<UserProfileViewModelKeys>(),
            nameof<UserProfileQueryFields>(),
            constructUsing(m => {
                const mapper: Record<UserProfileViewModelKeys, UserProfileQueryFields[]> = {
                    uid: ['uid'],
                    full_name: ['first_name', 'last_name'],
                    nickname: ['nickname'],
                    uid_link_security: ['uid'],
                    uid_link_cs: ['uid'],
                    uid_link_agent: ['uid'],
                    account_status: ['account_status'],
                    withdrawal_status: ['withdrawal_status'],
                    deposit_status: ['deposit_status'],
                    casino_status: ['casino_status'],
                    sportsbook_status: ['sportsbook_status'],
                    security_cases: ['security_cases.type', 'security_cases.case_id'],
                    'latest_kyc.payment.account_verification_status': [
                        'latest_kyc.payment.id',
                        'latest_kyc.payment.account_verification_status',
                    ],
                    'latest_kyc.payment.account_verification_status_cs': [
                        'latest_kyc.payment.id',
                        'latest_kyc.payment.account_verification_status',
                    ],
                    phone: ['contact.mobile.full_number'],
                    referrer_player_id: ['referral.inviting_user_id'],
                    country: ['address.country_info.iso_alpha2_code'],
                    lobby_access_status: ['lobby_access_status'],
                    'security_cases.type': ['security_cases.type'],
                    'security_cases.case_id': ['security_cases.case_id'],
                    'security_cases.closed_at': ['security_cases.closed_at'],
                    'security_cases.opened_at': ['security_cases.opened_at'],
                    'latest_kyc.security.id': ['latest_kyc.security.id'],
                    'latest_kyc.security.account_verification_status': [
                        'latest_kyc.security.id',
                        'latest_kyc.security.account_verification_status',
                    ],
                    username: ['username'],
                    p2p_transfer_status: ['p2p_transfer_status'],
                    platform: ['platform'],
                    platform_cs: ['platform'],
                    deposit_total: ['finance.deposit.total.successful_amount'],
                    withdrawal_approved_amount: ['finance.withdrawal.total.successful_amount'],
                    withdrawal_approved_count: ['finance.withdrawal.total.successful_count'],
                    withdrawal_pending_amount: ['finance.withdrawal.total.pending_amount'],
                    balance: ['finance.balance'],
                    ggr: ['finance.ggr'],
                    lifetime_bonus: ['finance.lifetime_bonus'],
                    bonus_ratio: ['finance.bonus_ratio'],
                    'agent_info.default_agent_revenue_share_history': ['agent_info.default_agent_revenue_share_history'],
                    'agent_info.bo_agent_id': ['agent_info.bo_agent_id'],
                    referrer_username: ['referral.inviting_user_username'],
                    email: ['contact.email'],
                    'finance.balance': ['finance.balance'],
                    referred_date: ['referral.referred_at'],
                    gender: ['gender'],
                    first_name: ['first_name'],
                    last_name: ['last_name'],
                    'phone.area': ['contact.mobile.area'],
                    'phone.number': ['contact.mobile.mobile'],
                    'birthday.seconds': ['birthday.seconds'],
                    'date_of_joining.seconds': ['date_of_joining.seconds'],
                    is_2fa_enabled: ['security_settings.is_2fa_enabled'],
                    is_challenge_questions_enabled: ['security_settings.is_challenge_questions_enabled'],
                    referrer: ['referral.inviting_user_id', 'affiliate.btag'],
                    register_marketing_code: ['marketing_info.register_marketing_code'],
                    labels: ['labels.id', 'labels.name', 'labels.group.id', 'labels.group.name', 'labels.group.color'],
                    national_id: ['region_player_info.national_id'],
                    networks_BL: ['networks.uid', 'networks.platform'],
                    networks_PKW: ['networks.uid', 'networks.platform'],
                    'referral.personal_referral_link': ['referral.personal_referral_link'],
                    'latest_login.logged_at_ts.seconds': ['latest_login.logged_at_ts.seconds'],
                    'latest_kyc.payment.id': ['latest_kyc.payment.id'],
                    'networks.uid': ['networks.uid'],
                    'networks.platform': ['networks.platform'],
                    aml_labels: ['aml_labels'],
                    login_countries: ['countries'],
                    vpn: ['vpn'],
                    brand: ['brand'],
                    license_type: ['license_type'],
                    'finance.withdrawal.total.pending_count': ['finance.withdrawal.total.pending_count'],
                    'finance.casino_coin_balance': ['finance.casino_coin_balance'],
                    'finance.p2p_credit.successful_amount': ['finance.p2p_credit.successful_amount'],
                    'finance.p2p_debit.successful_amount': ['finance.p2p_debit.successful_amount'],
                    casino_ggr_lifetime: ['finance.ggr_lifetime.casino'],
                    poker_ggr_lifetime: ['finance.ggr_lifetime.poker'],
                    sports_ggr_lifetime: ['finance.ggr_lifetime.sports'],
                    withdrawal_total: ['finance.withdrawal.total.successful_amount'],
                };

                return [...new Set(m.flatMap(i => mapper[i]))];
            })
        );
        createMap(
            mapper,
            UserProfile,
            UserProfileViewModel,
            forMember(
                vm => vm.uid,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.agent_info,
                mapWith(AgentInfoViewModel, AgentInfo, m => m.agent_info)
            ),
            forMember(
                vm => vm.username,
                mapFrom(m => m.username)
            ),
            forMember(
                vm => vm.security_cases,
                mapWith(SecurityCaseViewModel, SecurityCase, m => m.security_cases)
            ),
            forMember(
                vm => vm.account_status,
                mapFrom(m => m.account_status)
            ),
            forMember(
                vm => vm.deposit_status,
                mapFrom(m => m.deposit_status)
            ),
            forMember(
                vm => vm.casino_status,
                mapFrom(m => m.casino_status)
            ),
            forMember(
                vm => vm.withdrawal_status,
                mapFrom(m => m.withdrawal_status)
            ),
            forMember(
                vm => vm.lobby_access_status,
                mapFrom(m => m.lobby_access_status)
            ),
            forMember(
                vm => vm.p2p_transfer_status,
                mapFrom(m => m.p2p_transfer_status)
            ),
            forMember(
                vm => vm.sportsbook_status,
                mapFrom(m => m.sportsbook_status)
            ),
            forMember(
                vm => vm.brand,
                mapFrom(m => m.brand)
            ),
            forMember(
                vm => vm.license_type,
                mapFrom(m => m.license_type)
            )
        );
        createMap(
            mapper,
            AgentInfo,
            AgentInfoViewModel,
            forMember(
                vm => vm.bo_agent_id,
                mapFrom(m => m.bo_agent_id)
            ),
            forMember(
                vm => vm.revenue_share_history,
                mapWith(String, nameof<AgentRevenueShareViewModel>(), m => m.default_agent_revenue_share_history)
            )
        );
        createMap<String, AgentRevenueShareViewModel[]>(
            mapper,
            String,
            nameof<AgentRevenueShareViewModel>(),
            constructUsing(m => {
                let parsedVms: AgentRevenueShareViewModel[] = null;
                try {
                    parsedVms = JSON.parse(m.toString());
                } catch (e) {
                    parsedVms = [];
                }

                const convertToPercents = (value: number) => +(value * 100).toFixed(6);
                const updatedVms = produce(parsedVms, draftState => {
                    draftState.forEach(parsedVm => {
                        parsedVm.casino_share = convertToPercents(parsedVm?.casino_share);
                        parsedVm.gross_deposit = convertToPercents(parsedVm?.gross_deposit);
                        parsedVm.net_deposit_share = convertToPercents(parsedVm?.net_deposit_share);
                        parsedVm.player_winloss = convertToPercents(parsedVm?.player_winloss);
                        parsedVm.spins = convertToPercents(parsedVm?.spins);
                        parsedVm.poker_mtt = convertToPercents(parsedVm?.poker_mtt);
                        parsedVm.sports = convertToPercents(parsedVm?.sports);
                        Object.keys(parsedVm?.poker_cash ?? {}).forEach(
                            (key: keyof AgentPokerCashViewModel) =>
                                (parsedVm.poker_cash[key] = convertToPercents(parsedVm.poker_cash?.[key]))
                        );
                        (parsedVm?.poker_share?.weekly_bands ?? []).forEach(
                            weeklyBand => (weeklyBand.revenue_share = convertToPercents(weeklyBand?.revenue_share))
                        );
                    });
                });
                return updatedVms;
            })
        );
        createMap<AgentRevenueShareViewModel[], String>(
            mapper,
            nameof<AgentRevenueShareViewModel>(),
            String,
            constructUsing(vms => {
                const updatedVms = produce(vms, draftState => {
                    const convertToDecimal = (value: number) => +(value / 100).toFixed(6);

                    draftState.forEach(vm => {
                        if (vm?.poker_cash) {
                            delete vm?.poker_share;
                            Object.keys(vm?.poker_cash ?? {}).forEach(
                                (key: keyof AgentPokerCashViewModel) => (vm.poker_cash[key] = convertToDecimal(vm?.poker_cash?.[key]))
                            );
                        } else {
                            (vm?.poker_share?.weekly_bands ?? []).forEach(weeklyBand => {
                                weeklyBand.revenue_share = convertToDecimal(weeklyBand?.revenue_share);
                            });
                        }
                        vm.casino_share = convertToDecimal(vm?.casino_share);
                        vm.gross_deposit = convertToDecimal(vm?.gross_deposit);
                        vm.net_deposit_share = convertToDecimal(vm?.net_deposit_share);
                        vm.player_winloss = convertToDecimal(vm?.player_winloss);
                        vm.spins = convertToDecimal(vm?.spins);
                        vm.poker_mtt = convertToDecimal(vm?.poker_mtt);
                        vm.sports = convertToDecimal(vm?.sports);
                    });
                });
                return JSON.stringify(updatedVms);
            })
        );
        createMap<UserProfileViewModelKeys, UserProfileColumnSettingsKeys>(
            mapper,
            nameof<UserProfileViewModelKeys>(),
            nameof<UserProfileColumnSettingsKeys>(),
            constructUsing(m => {
                type FieldsToMap = Exclude<UserProfileViewModelKeys, UserProfileColumnSettingsKeys>;
                const mapping: Record<FieldsToMap, UserProfileColumnSettingsKeys> = {
                    'agent_info.bo_agent_id': null,
                    'agent_info.default_agent_revenue_share_history': null,
                    'birthday.seconds': null,
                    bonus_ratio: null,
                    casino_status: null,
                    sportsbook_status: null,
                    'date_of_joining.seconds': 'date_of_joining',
                    'finance.balance': 'balance',
                    'finance.withdrawal.total.pending_count': 'pending_count',
                    gender: null,
                    ggr: null,
                    is_2fa_enabled: null,
                    is_challenge_questions_enabled: null,
                    'latest_kyc.payment.account_verification_status': 'latest_payment_kyc_status',
                    'latest_kyc.payment.account_verification_status_cs': 'latest_payment_kyc_status',
                    'latest_kyc.security.account_verification_status': 'latest_security_kyc_status',
                    'latest_kyc.security.id': null,
                    'latest_login.logged_at_ts.seconds': 'latest_login_time',
                    lifetime_bonus: null,
                    lobby_access_status: null,
                    'phone.area': null,
                    'phone.number': null,
                    platform_cs: 'platform',
                    'referral.personal_referral_link': null,
                    'security_cases.case_id': null,
                    'security_cases.type': null,
                    'security_cases.closed_at': null,
                    'security_cases.opened_at': null,
                    uid_link_agent: 'uid',
                    uid_link_cs: 'uid',
                    uid_link_security: 'uid',
                    'latest_kyc.payment.id': null,
                    'networks.uid': null,
                    'networks.platform': null,
                    aml_labels: null,
                    vpn: null,
                    'finance.casino_coin_balance': null,
                    'finance.p2p_credit.successful_amount': null,
                    'finance.p2p_debit.successful_amount': null,
                };
                const mappedValue = mapping[m as FieldsToMap];
                return mappedValue === undefined ? m : mappedValue;
            })
        );
        createMap<UserProfileColumnSettingsKeys, UserProfileViewModelKeys[]>(
            mapper,
            nameof<UserProfileColumnSettingsKeys>(),
            nameof<UserProfileViewModelKeys>(),
            constructUsing(m => {
                type FieldsToMap =
                    | Exclude<UserProfileColumnSettingsKeys, UserProfileViewModelKeys>
                    | Extract<UserProfileColumnSettingsKeys, 'uid' | 'platform' | 'balance'>;

                const mapping: Record<FieldsToMap, UserProfileViewModelKeys[]> = {
                    __type: [],
                    balance: ['balance', 'finance.balance'],
                    latest_payment_kyc_status: [
                        'latest_kyc.payment.account_verification_status',
                        'latest_kyc.payment.account_verification_status_cs',
                    ],
                    latest_security_kyc_status: ['latest_kyc.security.account_verification_status'],
                    latest_login_time: ['latest_login.logged_at_ts.seconds'],
                    platform: ['platform', 'platform_cs'],
                    uid: ['uid', 'uid_link_agent', 'uid_link_cs', 'uid_link_security'],
                    date_of_joining: ['date_of_joining.seconds'],
                    pending_count: ['finance.withdrawal.total.pending_count'],
                };
                const mappedValue = mapping[m as FieldsToMap];
                return mappedValue === undefined ? [m] : mappedValue;
            })
        );

        createMap<ServerLicenseType, LicenseType>(
            mapper,
            nameof<ServerLicenseType>(),
            nameof<LicenseType>(),
            constructUsing(m => {
                const licenseTypeMapper: Record<ServerLicenseType, LicenseType> = {
                    CURACAO: LicenseType.CURACAO,
                    TOBIQUE: LicenseType.TOBIQUE,
                    MEXICO: LicenseType.MX,
                    PHILIPPINES: LicenseType.PH,
                    SOUTHKOREA: LicenseType.KR,
                };
                return licenseTypeMapper[m];
            })
        );
    }
}
