import {useCallback, useEffect, useState} from 'react';

import {useAutoMapper} from '@auto-mapper';
import {ServerLicenseType} from '@redux/entity/types/entityUserProfile';
import {LicenseType} from '@services/brandService';

import {validateCountry} from 'src/features/block-country';

import {useBrandContext} from './BrandProvider';
import {IBrandService} from './IBrandService';
import {BrandConfig} from './types';

export type UseBrandResult<TLicenseType = unknown> = {
    userBrandConfig: BrandConfig<TLicenseType>;
    getAppConfig: (licenses: TLicenseType[]) => BrandConfig<TLicenseType>;
};

export function useBrand<TLicenseType = unknown>(brandService: IBrandService<TLicenseType>): UseBrandResult<TLicenseType> {
    const [userBrandConfig, setUserBrandConfig] = useState<BrandConfig<TLicenseType>>();

    useEffect(() => {
        brandService.getUserConfig().subscribe(config => setUserBrandConfig(config));
    }, []);

    const getAppConfig = useCallback((licenses: TLicenseType[]) => brandService.getAppConfig(licenses), []);

    return {userBrandConfig, getAppConfig};
}

export function useCountryValidationByLicense(): (iso2Code: string, license: ServerLicenseType) => boolean {
    const {getAppConfig} = useBrandContext();
    const mapper = useAutoMapper();
    return (iso2Code: string, license: ServerLicenseType) =>
        validateCountry(
            iso2Code,
            getAppConfig([mapper.map<ServerLicenseType, LicenseType>(license, nameof<ServerLicenseType>(), nameof<LicenseType>())])
                ?.countryConfig
        );
}
