import React, {useContext} from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';

import Button from '@components/button/Buttons';
import LocalizedText from '@components/i18n/LocalizedText';
import {ModalContext} from '@components/modal';

import {VersionHistoryModal, VersionHistoryModalProps} from './VersionHistoryModal';

const localized = defineMessages({
    versionHistoryLabel: {
        id: 'LegalDocs_versionHistoryLabel',
        defaultMessage: 'Version History',
    },
});

type VersionHistoryButtonProps = Pick<VersionHistoryModalProps, 'licenseType' | 'documentType'> & {label?: MessageDescriptor};

export function VersionHistoryModalButton(props: VersionHistoryButtonProps) {
    const {openModal} = useContext(ModalContext);

    const handleClick = () => {
        openModal({
            title: <LocalizedText label={localized.versionHistoryLabel} />,
            body: <VersionHistoryModal title={localized.versionHistoryLabel} {...props} />,
            styleParams: {
                maxWidth: 'md',
            },
        });
    };

    return <Button color="primary" label={props?.label ?? localized.versionHistoryLabel} onClick={handleClick} />;
}
