import React, {useState} from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import {usePolicyAccessCheck} from '@access-control';
import Button from '@components/button/Buttons';
import {LegalDocType, License} from '@models';

import {VersionHistoryModalButton} from 'src/features/block-legal-doc-history';
import {privacyPolicyUpdateResource, termsAndConditionsUpdateResource} from '../permissions';

import {LegalDocumentFormContent} from './LegalDocumentForm';
import {LegalDocumentView} from './LegalDocumentView';

const localized = defineMessages({
    editButton: {
        id: 'LegalDocumentDetails_editButtonLabel',
        defaultMessage: 'Edit',
    },
});

const useStyles = makeStyles()(theme => ({
    legalDocumentModuleButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        justifyContent: 'end',
        margin: theme.spacing(2),
        '& .MuiButtonBase-root': {
            borderRadius: theme.shape.borderRadius * 2,
        },
    },
}));

type LegalDocumentDetailsProps = {
    licenseType: License;
    documentType: LegalDocType;
    content: string;
    required: boolean;
    version: number;
    name: MessageDescriptor;
    onSuccessUpdate?: () => void;
};

export function LegalDocumentModule({
    documentType,
    licenseType,
    content,
    name,
    required,
    version,
    onSuccessUpdate,
}: LegalDocumentDetailsProps) {
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const {classes} = useStyles();
    const updateAccess = usePolicyAccessCheck(
        documentType === LegalDocType.TermsAndConditions ? privacyPolicyUpdateResource : termsAndConditionsUpdateResource
    );

    function handleEditModeChange() {
        setEditMode(!isEditMode);
    }

    function handleSuccess() {
        handleEditModeChange();
        if (onSuccessUpdate) {
            onSuccessUpdate();
        }
    }

    return (
        <>
            <div className={classes.legalDocumentModuleButtonContainer}>
                {!isEditMode && updateAccess && <Button color="primary" label={localized.editButton} onClick={handleEditModeChange} />}
                <VersionHistoryModalButton licenseType={licenseType} documentType={documentType} />
            </div>
            {isEditMode ? (
                <LegalDocumentFormContent
                    document={content}
                    required={required}
                    documentType={documentType}
                    licenseType={licenseType}
                    onSuccess={handleSuccess}
                    onClose={handleEditModeChange}
                />
            ) : (
                <LegalDocumentView documentType={documentType} content={content} name={name} required={required} version={version} />
            )}
        </>
    );
}
