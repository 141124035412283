import {BaseFilterKeys, EntityFetchRequestPayload, Filter} from '@redux/entity';

export abstract class BaseRequestBuilder<TFilterKeys extends string = BaseFilterKeys, TQueryFields extends string = string> {
    public abstract buildRequest(requestPayload: EntityFetchRequestPayload<TQueryFields>): unknown;

    protected isFilterInvalid({invalid}: Filter): boolean {
        return <boolean>invalid;
    }

    protected mapToFilterObject(filter: string): Filter<TFilterKeys> {
        const result: Filter<TFilterKeys> = {};
        const searchParams = new URLSearchParams(filter);
        searchParams.forEach((value, key) => {
            result[key as TFilterKeys] = value;
        });
        return result;
    }

    protected toMultiselectFilter<TResult>(filter: Filter<TFilterKeys>, filterName: TFilterKeys): TResult {
        return filterName in filter
            ? ((this.getMultiselectFilterList(filter[filterName] as string) ?? filter[filterName]) as TResult)
            : undefined;
    }

    protected toStringFilter<TResult extends string = string>(filter: Filter<TFilterKeys>, filterName: TFilterKeys): TResult {
        return this.parseJSONFilter(filter, filterName)?.toString() as TResult;
    }

    protected toObjectFilter<TResult extends object>(filter: Filter<TFilterKeys>, filterName: TFilterKeys): TResult {
        const value = this.parseJSONFilter(filter, filterName);
        return (typeof value === 'object' ? value : undefined) as TResult;
    }

    protected toBooleanFilter(filter: Filter<TFilterKeys>, filterName: TFilterKeys): boolean {
        return !!this.parseJSONFilter(filter, filterName);
    }

    protected toNumberFilter(filter: Filter<TFilterKeys>, filterName: TFilterKeys): number {
        const value = Number(this.parseJSONFilter(filter, filterName));
        return isNaN(value) || value === null || value === undefined ? undefined : value;
    }

    protected buildPaging(filter: Filter): {end: number; start: number} {
        const defaultPaging = {end: 10, start: 0};
        return filter.page && filter.size
            ? {
                  start: ((filter.page as number) - 1) * (filter.size as number),
                  end: (filter.page as number) * (filter.size as number),
              }
            : defaultPaging;
    }

    private getMultiselectFilterList(serializedFilter: string): string[] {
        try {
            const value = JSON.parse(serializedFilter);
            return Array.isArray(value) ? value?.flatMap(v => v) : null;
        } catch (e) {
            return null;
        }
    }

    private parseJSONFilter<TResult>(filter: Filter<TFilterKeys>, filterName: TFilterKeys): TResult {
        let result: TResult;
        if (filterName in filter) {
            try {
                result = JSON.parse(filter[filterName] as string);
            } catch (e) {
                result = filter[filterName] as TResult;
            }
        } else {
            return undefined;
        }
        return result;
    }
}
