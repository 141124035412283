import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {LegalDocType, License} from '@models';
import {map} from '@otel';
import {ApiRoutesService} from '@services/rest-api/apiRoutesService';
import {RestService} from '@services/rest-api/restService';
import {ServerResponseStatus, ServiceResponsePayload} from '@services/types';

import {Jurisdiction} from 'src/features/app/config/types';

export class LegalDocument {
    isDeleted: boolean;
    actionTime: Date;
    author: string;
    document: string;
    required: boolean;
    version: number;
    licenseType: License;
    type: LegalDocType;
}

export type GetLegalDocumentRequest = {document: LegalDocType; query?: string};
export type GetLegalDocumentResponse = ServiceResponsePayload<GetLegalDocumentRequest, LegalDocument>;

export type EditLegalDocumentResponse = ServiceResponsePayload<LegalDocumentEditModel, null>;

export type LegalDocumentApiFilterKeys = 'version' | 'licenseType';

export class LegalDocumentEditModel {
    id: string;
    document: string;
    required: boolean;
    key: {
        jurisdiction: Jurisdiction;
        name: LegalDocType;
        licenseType: License;
    };
}

@injectable()
export class LegalDocumentApiService {
    private readonly _httpService: RestService;
    private readonly _apiRouteService: ApiRoutesService;

    constructor(
        @inject(ServiceTypes.RestService) httpService: RestService,
        @inject(ServiceTypes.ApiRoutesService) apiRouteService: ApiRoutesService
    ) {
        this._httpService = httpService;
        this._apiRouteService = apiRouteService;
    }

    public get(request: GetLegalDocumentRequest): Observable<GetLegalDocumentResponse> {
        const endpoint = this._apiRouteService.getLegalDocumentEndpoint(request.document);
        return this._httpService.get({endpoint, query: request.query}).pipe(
            map(response => ({
                ...response,
                requestPayload: request,
                responsePayload:
                    response?.status === ServerResponseStatus.Success
                        ? this.getResponse(response?.responsePayload?.response as LegalDocument, request.document)
                        : null,
            }))
        );
    }

    public edit(model: LegalDocumentEditModel): Observable<EditLegalDocumentResponse> {
        const endpoint = this._apiRouteService.editLegalDocumentEndpoint();
        return this._httpService
            .post({endpoint, body: model})
            .pipe(map(response => ({...response, requestPayload: model, responsePayload: null})));
    }

    private getResponse(response: LegalDocument, document: LegalDocType): LegalDocument {
        return {...response, type: document};
    }
}
