import {ExtendedMuiGridColDef, renderCellHeader, renderCellWithTooltip} from '@components/data-grid/mui';
import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';

import {FeatureName} from 'src/features/app/config/types';
import ModuleCreateAction from 'src/features/module-shared/components/ModuleCreateAction';
import {PageModuleAction} from 'src/features/module-shared/types';
import {PermissionEditableColDef} from 'src/features/module-temp-storage/components/editable-components/types';
import actions from '../actions';
import {localizedRoleManagement as localized} from '../localize';
import {RoleGridItem} from '../types';

import {renderViewEditAction} from './RolesEditAction';

const getGridColumns = () => {
    const columns: ExtendedMuiGridColDef[] = [
        {
            field: nameof<RoleGridItem>(u => u.id),
            hide: true,
        },
        {
            field: nameof<RoleGridItem>(u => u.name),
            renderHeader: () => renderCellHeader(localized.name),
        },
        {
            field: nameof<RoleGridItem>(u => u.userCount),
            renderHeader: () => renderCellHeader(localized.users),
            sortable: false,
        },
        {
            field: 'view',
            renderHeader: () => renderCellHeader(localized.viewAction),

            renderCell: p => renderViewEditAction(p, 'view'),
            sortable: false,
            permissions: [PermissionEnum.Read],
            moduleName: ModuleName.AccessManagement,
            submoduleName: SubmoduleName.Roles,
            flex: 0.1,
        } as PermissionEditableColDef,
        {
            field: 'edit',
            renderHeader: () => renderCellHeader(localized.editAction),
            renderCell: p => renderViewEditAction(p, 'edit'),
            sortable: false,
            permissions: [PermissionEnum.Update],
            moduleName: ModuleName.AccessManagement,
            submoduleName: SubmoduleName.Roles,
            featureName: FeatureName.RoleEdit,
            flex: 0.1,
        } as PermissionEditableColDef,
    ];

    const defaultCol: PermissionEditableColDef = {
        field: '',
        flex: 0.2,
        permissions: [PermissionEnum.Read],
        moduleName: ModuleName.AccessManagement,
        submoduleName: SubmoduleName.Roles,
        renderCell: renderCellWithTooltip,
    };

    return columns.map(
        c =>
            ({
                ...defaultCol,
                ...c,
            } as PermissionEditableColDef)
    );
};

const moduleActions: PageModuleAction[] = [
    {
        render: () => ModuleCreateAction({openFormAction: actions.openRoleForm}),
        permissions: [PermissionEnum.Create],
        moduleName: ModuleName.AccessManagement,
        submoduleName: SubmoduleName.Roles,
    },
];

export default {
    columns: getGridColumns(),
    actions: moduleActions,
    localized,
};
