import {constructUsing, createMap, forMember, mapFrom, Mapper} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {LegalDocument} from '@services/rest-api/legalDocumentApiService';
import {IsNever} from '@utils/type';

import {LegalDocumentSortingFields, LegalDocumentViewModel, LegalDocumentViewModelKeys} from './types';

export class LegalDocumentMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            LegalDocument,
            LegalDocumentViewModel,
            forMember(
                vm => vm.id,
                mapFrom(m => `${m.type}_${m.licenseType}_${m.version}`)
            ),
            forMember(
                vm => vm.author,
                mapFrom(m => m.author)
            ),
            forMember(
                vm => vm.version,
                mapFrom(m => m.version)
            ),
            forMember(
                vm => vm.actionTime,
                mapFrom(m => m.actionTime)
            ),
            forMember(
                vm => vm.document,
                mapFrom(m => m.document)
            ),
            forMember(
                vm => vm.licenseType,
                mapFrom(m => m.licenseType)
            ),
            forMember(
                vm => vm.required,
                mapFrom(m => m.required)
            ),
            forMember(
                vm => vm.type,
                mapFrom(m => m.type)
            )
        );
        createMap<LegalDocumentViewModelKeys, LegalDocumentSortingFields>(
            mapper,
            nameof<LegalDocumentViewModelKeys>(),
            nameof<LegalDocumentSortingFields>(),
            constructUsing(m => {
                type FieldsToMap = Exclude<LegalDocumentViewModelKeys, LegalDocumentSortingFields>;
                const isNever: IsNever<FieldsToMap> = true;
                if (!isNever) {
                    throw new Error('Mapping should be defined for all values');
                }
                return m;
            })
        );
    }
}
