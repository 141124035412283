import {defineMessages} from 'react-intl';

import {LegalDocHistoryViewModelKeys} from '../types';

export const localizedLegalDocHistoryDataGrid = defineMessages<LegalDocHistoryViewModelKeys>({
    id: null,
    user_id: {
        id: 'DataGridLegalDocHistory_user_id',
        defaultMessage: 'User ID',
    },
    user_email: {
        id: 'DataGridLegalDocHistory_user_email',
        defaultMessage: 'User Email',
    },
    version: {
        id: 'DataGridLegalDocHistory_version',
        defaultMessage: 'Version',
    },
    created_at: {
        id: 'DataGridLegalDocHistory_created_at',
        defaultMessage: 'Last Updated',
    },
    ip: {
        id: 'DataGridLegalDocHistory_created_at',
        defaultMessage: 'IP',
    },
    document: null,
    license_type: {
        id: 'DataGridLegalDocHistory_license_type',
        defaultMessage: 'License Type',
    },
    type: null,
});
