import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';

import {LayoutQueue} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import {LegalDocType} from '@models';
import {LegalDocumentFilterKeys} from '@redux/entity';
import {extendedViewCleanDelay, getNonEmptyValueValidator, ViewType} from '@redux/view';
import {updateKeyValueFilterArray} from '@utils';

import {LegalDocumentKeyValueFilter, useLegalDocuments} from '../hooks';
import {LegalDocumentViewModelKeys} from '../types';

import {DataGridLegalDocument, LegalDocumentGridViewModelKeys} from './DataGridLegalDocument';

const localized = defineMessages({
    termsAndConditionsTitle: {
        id: 'TermsAndConditions_displayName',
        defaultMessage: 'Terms & Conditions',
    },
    privacyPolicyTitle: {
        id: 'PrivacyPolicy_displayName',
        defaultMessage: 'Privacy Policy',
    },
});

type LegalDocumentQueueProps = {
    document: LegalDocType;
    viewType: ViewType;
    fields: LegalDocumentViewModelKeys[];
    defaultFilters?: LegalDocumentKeyValueFilter[];
    validateFilter?: (filter: LegalDocumentKeyValueFilter[]) => boolean;
};

export function LegalDocumentQueue({document, viewType, fields, defaultFilters, validateFilter}: LegalDocumentQueueProps) {
    const keys: LegalDocumentGridViewModelKeys[] = [...fields, 'view_document'];
    const title = getTitle(document);
    const {items, totalCount, searchFilter, handlePageSizeChange, handleSortChange, handlePageChange} = useLegalDocuments({
        viewType,
        fields,
        displayName: title,
        defaultFilters: getDefaultFilters(defaultFilters),
        validateFilter: getValidateFilter(validateFilter),
        syncWithUrl: true,
        cleanDelay: extendedViewCleanDelay,
    });

    function getTitle(type: LegalDocType): MessageDescriptor {
        return type === LegalDocType.TermsAndConditions ? localized.termsAndConditionsTitle : localized.privacyPolicyTitle;
    }

    function getDefaultFilters(filters: LegalDocumentKeyValueFilter[]): LegalDocumentKeyValueFilter[] {
        const documentFilterKey: LegalDocumentFilterKeys = 'document';
        return updateKeyValueFilterArray([{key: documentFilterKey, value: document}], ...(filters ?? []));
    }

    function getValidateFilter(
        validateFilter: (filter: LegalDocumentKeyValueFilter[]) => boolean
    ): (filter: LegalDocumentKeyValueFilter[]) => boolean {
        return filters =>
            getNonEmptyValueValidator<LegalDocumentFilterKeys>('document')(filters) && validateFilter ? validateFilter(filters) : true;
    }

    return (
        <LayoutQueue
            header={<PageHeader header={title} removeBottomSpacing />}
            body={
                <DataGridLegalDocument
                    columns={keys}
                    rows={items}
                    paging={{page: searchFilter?.paging?.page, pageSize: searchFilter?.paging?.pageSize, rowCount: totalCount}}
                    sortModel={{sortModel: searchFilter?.sorting}}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortChange={handleSortChange}
                    isFlexible
                    hidePagination
                />
            }
        />
    );
}
