import {injectable} from 'inversify';

import {DynamicConfig, globalConfig} from '../../../configuration';

@injectable()
export class ApiRoutesService {
    private readonly _config: DynamicConfig;

    constructor() {
        this._config = globalConfig.getConfig();
    }

    public getCashierLinkEndpoint(transactionId: string): string {
        return `${this._config.paymentManagementApiUrl}/api/v1/payments/withdrawal/${transactionId}/management`;
    }

    public getWithdrawalRiskStatusChangeEndpoint(): string {
        return `${this._config.paymentManagementApiUrl}/api/v1/payments/withdrawal/status/risk`;
    }

    public getWithdrawalPaymentStatusChangeEndpoint(): string {
        return `${this._config.paymentManagementApiUrl}/api/v1/payments/withdrawal/status/payments`;
    }

    public getEditPlayerEmailEndpoint(uid: string): string {
        return `${this._config.playerManagementApiUrl}/api/v1/player/${uid}/email`;
    }

    public getEditPlayerPhoneEndpoint(uid: string): string {
        return `${this._config.playerManagementApiUrl}/api/v1/player/${uid}/phone`;
    }

    public getChangeAccountLockStatusEndpoint(): string {
        return `${this._config.playerManagementApiUrl}/api/v1/accountstate/lock`;
    }

    public getChangeLobbyLockStatusEndpoint(): string {
        return `${this._config.playerManagementApiUrl}/api/v1/accountstate/lobby`;
    }

    public getPlo5GameBlindConfigEndpoint(): string {
        return `${this._config.plo5GameManagementApiUrl}/game/configuration/coin/rule/blind`;
    }

    public getPlo5GameTemplateListEndpoint(): string {
        return `${this._config.plo5GameManagementApiUrl}/templates`;
    }

    public getPlo5GameTemplateEnabledListEndpoint(): string {
        return `${this._config.plo5GameManagementApiUrl}/templates/enabled/id`;
    }

    public getPlo5GameTemplateEndpoint(id: number): string {
        return `${this._config.plo5GameManagementApiUrl}/templates/${id}`;
    }

    public getPlo5GameTemplateRoomListEndpoint(): string {
        return `${this._config.plo5GameManagementApiUrl}/rooms/template`;
    }

    public getPlo5GameTemplatePlayerListEndpoint(uid: number): string {
        return `${this._config.plo5GameManagementApiUrl}/templates/${uid}/players`;
    }

    public getPlo5GameTemplateEnableEndpoint(): string {
        return `${this._config.plo5GameManagementApiUrl}/templates/enabled/id`;
    }

    public getPlo5GameTemplateCreateEndpoint(): string {
        return `${this._config.plo5GameManagementApiUrl}/templates`;
    }

    public getPlo5GameRoomsEndpoint(): string {
        return `${this._config.plo5GameManagementApiUrl}/rooms`;
    }

    public getPlo5GameTemplateEditEndpoint(uid: string): string {
        return `${this._config.plo5GameManagementApiUrl}/templates/${uid}`;
    }

    public getPlo5GameRoomCloseEndpoint(roomId: string, ownerId: string): string {
        return `${this._config.plo5GameManagementApiUrl}/rooms/${roomId}/owner/${ownerId}`;
    }

    public getPlo5KickoffPlayerEndpoint(userId: string): string {
        return `${this._config.plo5GameManagementApiUrl}/players/${userId}/kickoff`;
    }

    public getRulesEndpoint(): string {
        return `${this._config.ruleApiUrl}/rules`;
    }

    public getRuleEndpoint(ruleId: string): string {
        return `${this._config.ruleApiUrl}/rules/${ruleId}`;
    }

    public getActivateRuleEndpoint(ruleId: string): string {
        return `${this._config.ruleApiUrl}/rules/active/${ruleId}`;
    }

    public getDeactivateRuleEndpoint(ruleId: string): string {
        return `${this._config.ruleApiUrl}/rules/inactive/${ruleId}`;
    }

    public getChangeRuleOrderEndpoint(ruleId: string): string {
        return `${this._config.ruleApiUrl}/rules/${ruleId}/order`;
    }

    public getCmsGamesEndpoint(): string {
        return `${this._config.cmsUrl}/api/games`;
    }

    public getCmsSuppliersEndpoint(): string {
        return `${this._config.cmsUrl}/api/suppliers`;
    }

    public getInternalUsersEndpoint(): string {
        return `${this._config.authApiUrl}/api/users`;
    }

    public setLoginAccessEndpoint(uid: string, isEnable: boolean): string {
        return `${this._config.authApiUrl}/api/users/${uid}/${isEnable ? 'enable' : 'disable'}`;
    }

    public getKeycloakUsersEndpoint(): string {
        return `${this._config.authApiUrl}/admin/realms/master/users`;
    }

    public getKeycloakUserEndpoint(id: string): string {
        return `${this._config.authApiUrl}/admin/realms/master/users/${id}`;
    }

    public getKeycloakUsersCountEndpoint(): string {
        return `${this._config.authApiUrl}/admin/realms/master/users/count`;
    }

    public getCountriesEndpoint(): string {
        return `${this._config.countriesApiUrl}/api/countries`;
    }

    public getStatesEndpoint(countryIsoCode: string): string {
        return `${this._config.countriesApiUrl}/api/countries/${countryIsoCode}/subdivisions`;
    }

    public getAttachmentDownloadEndpoint(): string {
        return `${this._config.attachmentsUrl}/api/attachments/download`;
    }

    public getAttachmentUploadEndpoint(): string {
        return `${this._config.attachmentsUrl}/api/attachments/upload`;
    }
    public getSportsbookGetRecordEndpoint(): string {
        return `${this._config.sportsbookApiUrl}/getRecord`;
    }

    public getLegalDocumentEndpoint(docType: 'TermsAndConditions' | 'PrivacyPolicy'): string {
        return `${this._config.configApiUrl}/api/v1/legal/WPTO/${docType}`;
    }

    public editLegalDocumentEndpoint(): string {
        return `${this._config.playerManagementApiUrl}/api/v1/legal`;
    }
}
