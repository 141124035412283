import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {MemoizedChip} from '@components/chip/ChipRenderer';
import {KeyValueCell, KeyValueColumn, KeyValueTable} from '@components/key-value-table';
import {AutoAdjustedWidthTooltip} from '@components/tooltip/AutoAdjustedWidthTooltip';
import {MultiLineAutoAdjustedWidthTooltip} from '@components/tooltip/MultiLineAutoAdjustedWidthTooltip';
import {EnablementMode} from '@config/enablementMode';
import {agentReadPolicies} from '@models/permissions/permissions';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {RealtimeUpdatesMode} from '@redux/realtime';
import {formatTimestamp, isStringNullOrEmpty} from '@utils';

import {FeatureName} from 'src/features/app/config/types';
import {UserProfileLicenseType} from 'src/features/block-user-profile-actions-pii/components/UserProfileLicenseType';
import {UserPlatform} from 'src/features/block-user-profile-list';
import {useJurisdictionConfig, useJurisdictionFeature} from '../../app/config/hooks';
import {useMultiplePermissions} from '../../app/permission/PermissionHoc';
import {UserLoginViewModelKeys, useUserLoginDetails} from '../../block-user-login';
import {UserProfileBirthday} from '../../block-user-profile-actions-pii/components/UserProfileBirthday';
import {UserProfileEmail} from '../../block-user-profile-actions-pii/components/UserProfileEmail';
import {UserProfileFirstName} from '../../block-user-profile-actions-pii/components/UserProfileFirstName';
import {UserProfileGender} from '../../block-user-profile-actions-pii/components/UserProfileGender';
import {UserProfileLastName} from '../../block-user-profile-actions-pii/components/UserProfileLastName';
import {UserProfileNickname} from '../../block-user-profile-actions-pii/components/UserProfileNickname';
import {UserProfilePhoneNumber} from '../../block-user-profile-actions-pii/components/UserProfilePhoneNumber';
import {useUserProfileDetails} from '../../block-user-profile-details';
import {emptyCellCharacter} from '../../module-shared/utils';

export const localized = defineMessages({
    title: {
        id: 'UserPersonalInfo_PersonalInfo',
        defaultMessage: 'Personal Info',
    },
    nickName: {
        id: 'UserPersonalInfo_nickName',
        defaultMessage: 'Game Nick Name',
    },
    firstName: {
        id: 'UserPersonalInfo_firstName',
        defaultMessage: 'First Name',
    },
    lastName: {
        id: 'UserPersonalInfo_lastName',
        defaultMessage: 'Last Name',
    },
    email: {
        id: 'UserPersonalInfo_email',
        defaultMessage: 'Email Address',
    },
    phone: {
        id: 'UserPersonalInfo_phone',
        defaultMessage: 'Phone Number',
    },
    birthday: {
        id: 'UserPersonalInfo_birthday',
        defaultMessage: 'D.O.B.',
    },
    gender: {
        id: 'UserPersonalInfo_gender',
        defaultMessage: 'Gender',
    },
    platform: {
        id: 'UserPersonalInfo_platform',
        defaultMessage: 'Game Platform',
    },
    brand: {
        id: 'UserPersonalInfo_brand',
        defaultMessage: 'Brand',
    },
    deviceId: {
        id: 'UserPersonalInfo_deviceId',
        defaultMessage: 'Register Device ID',
    },
    dateOfJoining: {
        id: 'UserPersonalInfo_dateOfJoining',
        defaultMessage: 'Date Of Joining',
    },
    challengeQuestions: {
        id: 'UserPersonalInfo_challengeQuestions',
        defaultMessage: 'Challenge Questions',
    },
    twoFA: {
        id: 'UserPersonalInfo_twoFA',
        defaultMessage: '2FA',
    },
    uid: {
        id: 'UserPersonalInfo_uid',
        defaultMessage: 'UID',
    },
    latestLoginDate: {
        id: 'UserPersonalInfo_latestLoginDate',
        defaultMessage: 'Last login date/time',
    },
    licenseType: {
        id: 'UserPersonalInfo_licenseType',
        defaultMessage: 'License Type',
    },
});

type UserPersonalInfo = {
    uid: string;
};

export function UserPersonalInfo({uid}: UserPersonalInfo) {
    const {formatMessage} = useIntl();
    const arePiiFieldsAvailable = useMultiplePermissions({restrictedPermissions: agentReadPolicies});
    const {locale} = useJurisdictionConfig();
    const userProfileKeys: UserProfileViewModelKeys[] = arePiiFieldsAvailable
        ? [
              'uid',
              'nickname',
              'first_name',
              'last_name',
              'email',
              'phone.area',
              'phone.number',
              'birthday.seconds',
              'gender',
              'platform',
              'brand',
              'date_of_joining.seconds',
              'latest_login.logged_at_ts.seconds',
              'is_2fa_enabled',
              'is_challenge_questions_enabled',
              'license_type',
              'country',
          ]
        : ['uid', 'email', 'phone.area', 'phone.number'];
    const userLoginKeys: UserLoginViewModelKeys[] = arePiiFieldsAvailable ? ['session_id', 'uid', 'device.uuid'] : [];
    const {item: user} = useUserProfileDetails({
        id: uid,
        fields: userProfileKeys,
        viewType: 'PersonalInfo',
        realtimeMode: RealtimeUpdatesMode.Confirm,
        displayName: localized.title,
    });
    const {item: firstLogin} = useUserLoginDetails({
        fields: userLoginKeys,
        viewType: 'PersonalInfo',
        realtimeMode: RealtimeUpdatesMode.Confirm,
        displayName: localized.title,
        defaultFilters: [{key: 'uid', value: uid}],
        validateFilter: filter => userLoginKeys?.length > 0 && !isStringNullOrEmpty(filter?.find(f => f.key === 'uid')?.value),
    });

    const isLicenseTypeAvailable = useJurisdictionFeature({featureName: FeatureName.LicenseTypeDisplayAndEdit});

    return (
        <KeyValueTable>
            <KeyValueColumn>
                {userProfileKeys.includes('first_name') ? (
                    <KeyValueCell title={formatMessage(localized.firstName)}>
                        <UserProfileFirstName userId={uid} user={user} />
                    </KeyValueCell>
                ) : null}
                {userProfileKeys.includes('birthday.seconds') ? (
                    <KeyValueCell title={formatMessage(localized.birthday)}>
                        <UserProfileBirthday userId={uid} user={user} locale={locale} />
                    </KeyValueCell>
                ) : null}
                {userProfileKeys.includes('email') ? (
                    <KeyValueCell title={formatMessage(localized.email)}>
                        <UserProfileEmail userId={uid} user={user} />
                    </KeyValueCell>
                ) : null}
                {userProfileKeys.includes('uid') ? (
                    <KeyValueCell title={formatMessage(localized.uid)}>
                        <AutoAdjustedWidthTooltip value={uid} />
                    </KeyValueCell>
                ) : null}
                {userProfileKeys.includes('platform') ? (
                    <KeyValueCell title={formatMessage(localized.platform)}>
                        <UserPlatform value={user?.platform} />
                    </KeyValueCell>
                ) : null}
                {userProfileKeys.includes('brand') ? (
                    <KeyValueCell title={formatMessage(localized.brand)}>
                        <UserPlatform value={user?.brand} />
                    </KeyValueCell>
                ) : null}
                {userProfileKeys.includes('date_of_joining.seconds') ? (
                    <KeyValueCell title={formatMessage(localized.dateOfJoining)}>
                        <AutoAdjustedWidthTooltip value={formatTimestamp(user?.date_of_joining, 'date-time')} />
                    </KeyValueCell>
                ) : null}
                {isLicenseTypeAvailable && userProfileKeys.includes('license_type') ? (
                    <KeyValueCell title={formatMessage(localized.licenseType)} pinTitleToTop>
                        <UserProfileLicenseType
                            userId={uid}
                            licenseType={user?.license_type}
                            country={{
                                iso2Code: user?.address?.country_info?.iso_alpha2_code,
                                name: user?.address?.country_info?.name,
                            }}
                            state={user?.address?.state}
                        />
                    </KeyValueCell>
                ) : null}
                {!isLicenseTypeAvailable && userProfileKeys.includes('is_2fa_enabled') ? (
                    <KeyValueCell title={formatMessage(localized.twoFA)}>
                        <MemoizedChip
                            chipName={nameof<EnablementMode>()}
                            value={user?.security_settings?.is_2fa_enabled ? EnablementMode.Enabled : EnablementMode.Disabled}
                        />
                    </KeyValueCell>
                ) : null}
            </KeyValueColumn>
            <KeyValueColumn>
                {userProfileKeys.includes('last_name') ? (
                    <>
                        <KeyValueCell title={formatMessage(localized.lastName)}>
                            <UserProfileLastName userId={uid} user={user} />
                        </KeyValueCell>
                    </>
                ) : null}
                {userProfileKeys.includes('phone.area') && userProfileKeys.includes('phone.number') ? (
                    <KeyValueCell title={formatMessage(localized.phone)}>
                        <UserProfilePhoneNumber userId={uid} user={user} />
                    </KeyValueCell>
                ) : null}
                {userProfileKeys.includes('gender') ? (
                    <KeyValueCell title={formatMessage(localized.gender)} withOverflow>
                        <UserProfileGender userId={uid} user={user} />
                    </KeyValueCell>
                ) : null}
                {userProfileKeys.includes('nickname') ? (
                    <KeyValueCell title={formatMessage(localized.nickName)}>
                        <UserProfileNickname userId={uid} user={user} />
                    </KeyValueCell>
                ) : null}
                {userLoginKeys.includes('device.uuid') ? (
                    <KeyValueCell title={formatMessage(localized.deviceId)}>
                        <AutoAdjustedWidthTooltip value={firstLogin?.device?.uuid} />
                    </KeyValueCell>
                ) : null}
                {userProfileKeys.includes('latest_login.logged_at_ts.seconds') ? (
                    <KeyValueCell title={formatMessage(localized.latestLoginDate)}>
                        <MultiLineAutoAdjustedWidthTooltip linesCount={1}>
                            <>
                                {user?.latest_login?.logged_at_ts
                                    ? formatTimestamp(user.latest_login.logged_at_ts, 'date-time-with-seconds')
                                    : emptyCellCharacter}
                            </>
                        </MultiLineAutoAdjustedWidthTooltip>
                    </KeyValueCell>
                ) : null}
                {userProfileKeys.includes('is_challenge_questions_enabled') ? (
                    <KeyValueCell title={formatMessage(localized.challengeQuestions)}>
                        <MemoizedChip
                            chipName={nameof<EnablementMode>()}
                            value={
                                user?.security_settings?.is_challenge_questions_enabled ? EnablementMode.Enabled : EnablementMode.Disabled
                            }
                        />
                    </KeyValueCell>
                ) : null}
                {isLicenseTypeAvailable && userProfileKeys.includes('is_2fa_enabled') ? (
                    <KeyValueCell title={formatMessage(localized.twoFA)}>
                        <MemoizedChip
                            chipName={nameof<EnablementMode>()}
                            value={user?.security_settings?.is_2fa_enabled ? EnablementMode.Enabled : EnablementMode.Disabled}
                        />
                    </KeyValueCell>
                ) : null}
            </KeyValueColumn>
        </KeyValueTable>
    );
}
