import React from 'react';
import {defineMessages} from 'react-intl';
import {useSelector} from 'react-redux';

import {LayoutQueue} from '@components/layout';
import {LegalDocType, License} from '@models';
import {LegalDocumentFilterKeys} from '@redux/entity';
import {getNonEmptyValueValidator, ViewType} from '@redux/view';
import {getValueFromQueryFilter} from '@utils/query';

import {useBreadcrumbInitializer} from '../../app/breadcrumb/hooks';
import {locationSearchSelector} from '../../app/routing/selectors';
import {WorkspaceHeader} from '../../block-workspace-details/components/WorkspaceHeader';
import {useLegalDocuments} from '../hooks';

import {LegalDocumentModule} from './LegalDocumentModule';

const localized = defineMessages({
    termsAndConditionsTitle: {
        id: 'LegalDocumentDetailsTAC_displayName',
        defaultMessage: 'Terms & Conditions',
    },
    privacyPolicyTitle: {
        id: 'LegalDocumentDetailsPP_displayName',
        defaultMessage: 'Privacy Policy',
    },
});

type LegalDocumentDetailsProps = {
    documentType: LegalDocType;
    viewType: ViewType;
};

export function LegalDocumentDetails({documentType, viewType}: LegalDocumentDetailsProps) {
    const queryString = useSelector(locationSearchSelector);
    const license = getValueFromQueryFilter<LegalDocumentFilterKeys, License>(queryString, 'licenseType');
    const title = documentType === LegalDocType.TermsAndConditions ? localized.termsAndConditionsTitle : localized.privacyPolicyTitle;
    const {items, forceViewUpdate} = useLegalDocuments({
        viewType,
        fields: ['licenseType', 'version', 'actionTime', 'document', 'type'],
        displayName: title,
        defaultFilters: [
            {key: 'document', value: documentType},
            {key: 'licenseType', value: license},
        ],
        validateFilter: getNonEmptyValueValidator<LegalDocumentFilterKeys>('document', 'licenseType'),
        syncWithUrl: true,
    });

    const document = items?.[0];

    useBreadcrumbInitializer(license, undefined, undefined, title);

    return (
        <LayoutQueue
            header={<WorkspaceHeader>{null}</WorkspaceHeader>}
            body={
                <LegalDocumentModule
                    documentType={document?.type}
                    version={document?.version}
                    name={title}
                    required={document?.required}
                    licenseType={document?.licenseType}
                    content={document?.document}
                    onSuccessUpdate={forceViewUpdate}
                />
            }
        />
    );
}
