import {
    AccountVerification,
    ActiveAccountVerification,
    Affiliates,
    AgentInfo,
    BlackListItem,
    CountryInfo,
    Device,
    GgrLifetime,
    KycDocument,
    Location,
    PhoneNumber,
    PlayerFinanceSummary,
    Referrals,
    RegionPlayerInfo,
    SecurityCase,
    TransactionGroupSummary,
    TransactionSummary,
    UserAddressInfo,
    UserBlackList,
    UserContactInfo,
    UserLabel,
    UserLabelGroup,
    UserLogin,
    UserMarketingInfo,
    UserNetworkProfile,
    UserProfile,
    UserSecuritySettings,
} from '@models/generated/graphql';
import {TimestampKeys} from '@models/shared';
import {AgentReferrerFilterKeys} from '@redux/entity';

import {baseFilterKeys} from './base';

export class ReferralsExtended extends Referrals {
    personal_referral_link: string;
}

export class UserProfileExtended extends UserProfile {
    declare referral?: ReferralsExtended;
}

const userProfileTextFilterKeys = [
    'uid_un_fn_ln_em_ph_rmc',
    'uid_un_nn_em_sc_nuid',
    'uid_un_fn_ln_labels_em_ni_rmc_phone_r',
    'uid_un_nn_em_l_sc_nuid_ref',
    'uid_un_rmc_em',
    'uid',
    'firstName',
    'lastName',
    'username',
    'nickname',
    'email',
    'phone',
    'security_cases',
    'marketingCode',
    'labels',
    'networks_PKW',
    'networks_BL',
    'referrerId',
    'nationalId',
    'boUserId',
    'boUserId_uid',
    'registrationCountry',
    'loginCountry',
] as const;

export type UserProfileServerTextFilterKeys = (typeof userProfileTextFilterKeys)[number];

const userProfileOtherFilterKeys = [
    'birthdayFrom',
    'birthdayTo',
    'joinedFrom',
    'joinedTo',
    'accountStatus',
    'depositStatus',
    'withdrawalStatus',
    'kycStatus',
    'securityCases',
    'playersGroups',
    'casinoGgrFrom',
    'casinoGgrTo',
    'pokerGgrFrom',
    'pokerGgrTo',
    'sportsGgrFrom',
    'sportsGgrTo',
    'depositTotalFrom',
    'depositTotalTo',
    'withdrawalTotalFrom',
    'withdrawalTotalTo',
] as const;

export const userProfileFilterKeys = [...baseFilterKeys, ...userProfileTextFilterKeys, ...userProfileOtherFilterKeys];
export type UserProfileServerFilterKeys = (typeof userProfileFilterKeys)[number] | AgentReferrerFilterKeys;

type UserProfileKeys = keyof UserProfileExtended;

const networksKey: UserProfileKeys = 'networks';
type UserNetworkProfileKeys = keyof UserNetworkProfile;

const birthdayKey: UserProfileKeys = 'birthday';
const dateOfJoiningKey: UserProfileKeys = 'date_of_joining';

const contactKey: UserProfileKeys = 'contact';
type UserContactInfoKeys = keyof UserContactInfo;
type PhoneNumberKeys = keyof PhoneNumber;

const addressKey: UserProfileKeys = 'address';
type UserAddressInfoKeys = keyof UserAddressInfo;
type CountryInfoKeys = keyof CountryInfo;

const securitySettingsKey: UserProfileKeys = 'security_settings';
type UserSecuritySettingsKeys = keyof UserSecuritySettings;

type UserBlackListKeys = keyof UserBlackList;
type BlackListItemKeys = keyof BlackListItem;
const blackListKey: UserProfileKeys = 'black_list';

type DeviceKeys = keyof Device;
const devicesKey: UserProfileKeys = 'devices';

type SecurityCaseKeys = keyof SecurityCase;
const securityCasesKey: UserProfileKeys = 'security_cases';

type UserLoginKeys = keyof UserLogin;
type LocationKeys = keyof Location;
const latestLoginKey: UserProfileKeys = 'latest_login';

type PlayerFinanceSummaryKeys = keyof Omit<PlayerFinanceSummary, 'p2p_debit' | 'p2p_credit' | 'ggr_lifetime'>;
const p2pDebitKey: keyof PlayerFinanceSummary = 'p2p_debit';
const p2pCreditKey: keyof PlayerFinanceSummary = 'p2p_credit';
const ggrLifetimeKey: keyof PlayerFinanceSummary = 'ggr_lifetime';
type GGRLifetimeKeys = keyof GgrLifetime;
type TransactionSummaryKeys = keyof TransactionSummary;
type TransactionGroupSummaryKeys = keyof TransactionGroupSummary;
const financeKey: UserProfileKeys = 'finance';

type ActiveAccountVerificationKeys = keyof ActiveAccountVerification;
type AccountVerificationKeys = keyof AccountVerification;
type KycDocumentKeys = keyof KycDocument;
const latestKycKey: UserProfileKeys = 'latest_kyc';

type AffiliatesKeys = keyof Affiliates;
const affiliateKey: UserProfileKeys = 'affiliate';

type ReferralsKeys = keyof ReferralsExtended;
const referralKey: UserProfileKeys = 'referral';

type AgentInfoKeys = keyof AgentInfo;
const agentInfoKey: UserProfileKeys = 'agent_info';

type RegionPlayerInfoKeys = keyof RegionPlayerInfo;
const regionInfoKey: UserProfileKeys = 'region_player_info';

type UserMarketingInfoKeys = keyof UserMarketingInfo;
const marketingInfoKey: UserProfileKeys = 'marketing_info';

type UserLabelKeys = keyof UserLabel;
type UserLabelGroupKeys = keyof UserLabelGroup;
const labelsKey: UserProfileKeys = 'labels';

export type UserProfileQueryFields =
    | UserProfileKeys
    | `${typeof networksKey}.${UserNetworkProfileKeys}`
    | `${typeof birthdayKey}.${TimestampKeys}`
    | `${typeof dateOfJoiningKey}.${TimestampKeys}`
    | `${typeof contactKey}.${UserContactInfoKeys}`
    | `${typeof contactKey}.${UserContactInfoKeys}.${PhoneNumberKeys}`
    | `${typeof addressKey}.${UserAddressInfoKeys}.${CountryInfoKeys}`
    | `${typeof addressKey}.${UserAddressInfoKeys}`
    | `${typeof securitySettingsKey}.${UserProfileKeys}`
    | `${typeof securitySettingsKey}.${UserSecuritySettingsKeys}`
    | `${typeof blackListKey}.${UserBlackListKeys}`
    | `${typeof blackListKey}.${UserBlackListKeys}.${BlackListItemKeys}`
    | `${typeof devicesKey}.${DeviceKeys}`
    | `${typeof securityCasesKey}.${SecurityCaseKeys}`
    | `${typeof latestLoginKey}.${UserLoginKeys}`
    | `${typeof latestLoginKey}.${UserLoginKeys}.${DeviceKeys}`
    | `${typeof latestLoginKey}.${UserLoginKeys}.${LocationKeys}`
    | `${typeof latestLoginKey}.${UserLoginKeys}.${TimestampKeys}`
    | `${typeof financeKey}.${PlayerFinanceSummaryKeys}`
    | `${typeof financeKey}.${PlayerFinanceSummaryKeys}.${TransactionSummaryKeys}`
    | `${typeof financeKey}.${PlayerFinanceSummaryKeys}.${TransactionSummaryKeys}.${TransactionGroupSummaryKeys}`
    | `${typeof financeKey}.${typeof p2pDebitKey}.${TransactionGroupSummaryKeys}`
    | `${typeof financeKey}.${typeof p2pCreditKey}.${TransactionGroupSummaryKeys}`
    | `${typeof financeKey}.${typeof ggrLifetimeKey}.${GGRLifetimeKeys}`
    | `${typeof latestKycKey}.${ActiveAccountVerificationKeys}`
    | `${typeof latestKycKey}.${ActiveAccountVerificationKeys}.${AccountVerificationKeys}`
    | `${typeof latestKycKey}.${ActiveAccountVerificationKeys}.${AccountVerificationKeys}.${KycDocumentKeys}`
    | `${typeof affiliateKey}.${AffiliatesKeys}`
    | `${typeof referralKey}.${ReferralsKeys}`
    | `${typeof agentInfoKey}.${AgentInfoKeys}`
    | `${typeof agentInfoKey}.${AgentInfoKeys}.${TimestampKeys}`
    | `${typeof regionInfoKey}.${RegionPlayerInfoKeys}`
    | `${typeof addressKey}.${UserAddressInfoKeys}`
    | `${typeof marketingInfoKey}.${UserMarketingInfoKeys}`
    | `${typeof labelsKey}.${UserLabelKeys}`
    | `${typeof labelsKey}.${UserLabelKeys}.${UserLabelGroupKeys}`;

export const serverLicenses = ['CURACAO', 'TOBIQUE', 'MEXICO', 'PHILIPPINES', 'SOUTHKOREA'] as const;
export type ServerLicenseType = (typeof serverLicenses)[number];
