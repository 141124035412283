import React, {createContext, PropsWithChildren, useContext} from 'react';

import {useBrand, UseBrandResult} from './hooks';
import {IBrandService} from './IBrandService';

type BrandContextProps = UseBrandResult;

export const BrandContext = createContext<BrandContextProps>(null);

type BrandProviderProps = PropsWithChildren<{
    brandService: IBrandService;
}>;

export function BrandProvider({children, brandService}: BrandProviderProps) {
    const brandContextValue = useBrand(brandService);

    return <BrandContext.Provider value={brandContextValue}>{children}</BrandContext.Provider>;
}

export function useBrandContext(): UseBrandResult {
    const context = useContext(BrandContext);
    return context;
}
