import {Location} from 'history';

import {Filter, Paging, SearchFilter, Sorting} from '../types';

export const queryKeys = {
    page: 'page',
    pageSize: 'size',
    /**
     * @deprecated
     * <p>Should be removed. Use {@link sortField}</p>
     */
    sort: 'sort',
    /**
     * @deprecated
     * <p>Should be removed. Use {@link sortOrder}</p>
     */
    order: 'order',
    sortField: 'sortField',
    sortOrder: 'sortOrder',
    selectedItemId: 'selected_item_id',
    jurisdiction: 'jurisdiction',
    invalidFilter: 'invalid',
};

export const firstPageDefault: Paging = {page: 1, pageSize: 10};

export function toSearchFilter(query: string): SearchFilter {
    // eslint-disable-next-line node/no-unsupported-features/node-builtins
    const searchParams = new URLSearchParams(query ?? '');
    const filters: Filter[] = [];
    let {page, pageSize} = firstPageDefault;
    let sort: string[] = [],
        order: string[] = [];
    let parsedValue = '';
    let selectedItemId: string = null;

    searchParams.forEach((value, key) => {
        switch (key) {
            case queryKeys.selectedItemId:
                selectedItemId = value;
                break;
            case queryKeys.page:
                page = parseInt(value);
                break;
            case queryKeys.pageSize:
                pageSize = parseInt(value);
                break;
            case queryKeys.sort:
                sort = value.split(',');
                break;
            case queryKeys.sortField:
                sort = value?.split(',');
                break;
            case queryKeys.order:
                order = value.split(',');
                break;
            case queryKeys.sortOrder:
                order = value?.split(',');
                break;
            default:
                try {
                    parsedValue = JSON.parse(value);
                } catch (err) {
                    parsedValue = JSON.parse(JSON.stringify(value));
                }

                filters.push({key, value: parsedValue});
                break;
        }
    });

    return {
        filter: filters,
        paging: {page: page, pageSize: pageSize},
        sorting: sort.map((s, i) => ({field: s, sort: order[i]} as Sorting<string>)),
        selectedItemId,
    };
}

export function appendQuery(pathname: string, query: string): string {
    return query ? `${pathname}?${query}` : pathname;
}

export function getFilterString(searchString: string, changePaging = false, ...filters: Filter[]): string {
    // eslint-disable-next-line node/no-unsupported-features/node-builtins
    const searchParams = new URLSearchParams(searchString ?? '');
    if (filters) {
        filters.forEach(filter => {
            if (filter.value !== undefined && filter.value !== null && filter.value !== '') {
                const value = typeof filter.value === 'string' ? filter.value : JSON.stringify(filter.value);
                searchParams.set(filter.key, value);
            } else {
                searchParams.delete(filter.key);
            }
        });
    }

    const page = searchParams.get(queryKeys.page);
    if (page && parseInt(page) !== 1 && changePaging) {
        searchParams.set(queryKeys.page, `${1}`);
    }

    const query = searchParams.toString();

    return query;
}

export function getUrlWithoutUtilityFilters(filter: string): string {
    const searchParams = new URLSearchParams(filter ?? '');
    if (searchParams.has(queryKeys.invalidFilter)) {
        searchParams.delete(queryKeys.invalidFilter);
    }

    return searchParams.toString();
}

export function appendFilterToQuery(location: Location<unknown>, filter: Filter): string {
    return appendQuery(location.pathname, getFilterString(location.search, false, filter));
}

/**
 * @deprecated
 * <p>Should be removed. Use {@link mapSortingToFilter}</p>
 */
export function getSortString(searchString: string, sorting: Sorting<string>[]): string {
    // eslint-disable-next-line node/no-unsupported-features/node-builtins
    const searchParams = new URLSearchParams(searchString ?? '');
    if (sorting && sorting.length) {
        const sort = sorting.map(s => s.field).join();
        const order = sorting.map(s => s.sort).join();
        searchParams.set(queryKeys.sort, sort);
        searchParams.set(queryKeys.order, order);
    } else {
        searchParams.delete(queryKeys.sort);
        searchParams.delete(queryKeys.order);
    }
    const query = searchParams.toString();

    return query;
}

export function appendSortToQuery(location: Location<unknown>, sorting: Sorting<string>[]): string {
    return appendQuery(location.pathname, getSortString(location.search, sorting));
}

export function getPagingString(searchString: string, paging: Paging): string {
    // eslint-disable-next-line node/no-unsupported-features/node-builtins
    const searchParams = new URLSearchParams(searchString ?? '');
    if (paging?.page) {
        searchParams.set(queryKeys.page, paging.page.toString());
    }
    if (paging?.pageSize) {
        searchParams.set(queryKeys.pageSize, paging.pageSize.toString());
    }
    const query = searchParams.toString();

    return query;
}

export function appendPagingToQuery(location: Location<unknown>, paging: Paging): string {
    return appendQuery(location.pathname, getPagingString(location.search, paging));
}

export function getSelectedItemString(searchString: string, id: string): string {
    // eslint-disable-next-line node/no-unsupported-features/node-builtins
    const searchParams = new URLSearchParams(searchString ?? '');
    if (id) {
        searchParams.set(queryKeys.selectedItemId, id);
    }
    const query = searchParams.toString();

    return query;
}

export function appendSelectedItemToQuery(pathname: string, id: string): string {
    return appendQuery(pathname, getSelectedItemString('', id));
}

export function getSelectedItemId(search: string): string {
    const searchParams = new URLSearchParams(search ?? '');
    return searchParams.get(queryKeys.selectedItemId);
}

export function getJurisdiction(search: string): string {
    return new URLSearchParams(search ?? '').get(queryKeys.jurisdiction);
}

export function getValueFromQueryFilter<TKeys extends string, TValue = unknown>(filter: string, filterKeys: TKeys): TValue {
    const searchFilter = new URLSearchParams(filter ?? '');
    return searchFilter.get(filterKeys) as TValue;
}

export function setValueToQueryFilter<TKeys extends string>(filter: string, ...filters: Filter<string, TKeys>[]): string {
    const searchFilter = new URLSearchParams(filter ?? '');
    filters.forEach(f => {
        if (f.value) {
            searchFilter.set(f.key, f.value);
        } else {
            searchFilter.delete(f.key, f.value);
        }
    });
    return searchFilter.toString();
}
