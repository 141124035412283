import React from 'react';
import {Control, Controller, UseFormResetField, useWatch} from 'react-hook-form';
import {defineMessages, useIntl} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import {AccessControlIndicator, withPolicyAccessCheck} from '@access-control';
import {EditMode} from '@components/button/EditButton';
import {EditableTextInternal} from '@components/editable/EditableText';
import {withEditButton} from '@components/editable/EditButtonHoc';
import {RuleType, useValidationFormatter} from '@components/input';
import {EmptyPlaceholder, Select} from '@components/select';
import {serverLicenses, ServerLicenseType} from '@redux/entity/types/entityUserProfile';
import {useCountryValidationByLicense} from '@brand';

import {isStateRequired} from 'src/features/block-country';
import {SelectOption} from 'src/features/module-shared/types';
import {useReduxForm, useReduxFormRequestMode} from 'src/features/shared/form/hooks';
import {CountryAutocomplete} from 'src/features/view-autocomplete/components/CountryAutocomplete';
import {StateAutocomplete} from 'src/features/view-autocomplete/components/StateAutocomplete';
import {EditLicenseTypeRequestPayload, personalInfoActions} from '../actions';
import {userProfileLicenseTypeEditResourse} from '../permissions';

const localized = defineMessages({
    wrongCountry: {
        id: 'UserProfileLicenseType_wrongCountry',
        defaultMessage: '{country} country can not be used under {license} license',
    },
    state: {
        id: 'UserProfileLicenseType_state',
        defaultMessage: 'State',
    },
    country: {
        id: 'UserProfileLicenseType_country',
        defaultMessage: 'Country',
    },
    licenseType: {
        id: 'UserProfileLicenseType_licenseType',
        defaultMessage: 'License Type',
    },
});

type UserProfileLicenseTypeFormModel = {
    licenseType?: string;
    country?: {
        value: string;
        label: string;
    };
    state?: {
        value: string;
        label: string;
    };
};

type UserProfileLicenseTypeInnerProps = {
    disabled?: boolean;
    mode?: EditMode;
    control?: Control;
    resetField?: UseFormResetField<UserProfileLicenseTypeFormModel>;
};

const useClasses = makeStyles()(theme => ({
    container: {
        display: 'flex',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
    },
    selectContainer: {
        width: '100%',
        paddingLeft: theme.spacing(1.5),
        '&:not(:last-of-type)': {
            margin: 0,
            '& > .MuiInputBase-root': {
                maxWidth: 'unset',
            },
        },
    },
}));

function UserProfileLicenseTypeInternal({control, mode, resetField}: UserProfileLicenseTypeInnerProps) {
    const validationFormatter = useValidationFormatter();
    const {formatMessage} = useIntl();
    const {classes} = useClasses();

    const watched = useWatch({control: control});

    const licenseTypeOptions: SelectOption<ServerLicenseType>[] = serverLicenses.map(i => ({
        value: i,
        label: i,
    }));

    const validateCountry = useCountryValidationByLicense();

    return mode !== 'Read' ? (
        <div className={classes.container}>
            <Controller
                name={'licenseType'}
                render={({field}) => {
                    return (
                        <Select
                            emptyPlaceholder={EmptyPlaceholder.Dash}
                            className={classes.selectContainer}
                            options={licenseTypeOptions}
                            value={field.value}
                            onSubmit={(value: ServerLicenseType[]) => field.onChange(value[0])}
                        />
                    );
                }}
                rules={{
                    required: validationFormatter(RuleType.Required, localized.licenseType),
                }}
                control={control}
            />
            <Controller
                name={'country'}
                render={({field, fieldState}) => (
                    <CountryAutocomplete
                        license={watched?.licenseType}
                        errorMessage={fieldState?.error?.message}
                        value={field.value?.value}
                        label={field.value?.label}
                        onOptionChange={v => {
                            field.onChange(v);
                            resetField?.('state', {
                                defaultValue: {
                                    value: '',
                                    label: '',
                                },
                            });
                        }}
                    />
                )}
                rules={{
                    validate: {
                        validateCountryForLicenseType: value => {
                            const isValid = validateCountry(value?.value, watched?.licenseType);
                            return isValid !== true
                                ? formatMessage(localized.wrongCountry, {
                                      country: value?.label,
                                      license: watched?.licenseType,
                                  })
                                : null;
                        },
                        required: value => {
                            return !value?.value?.length ? validationFormatter(RuleType.Required, localized.country) : null;
                        },
                    },
                }}
                control={control}
            />
            <Controller
                name={'state'}
                render={({field, fieldState}) => (
                    <StateAutocomplete
                        errorMessage={fieldState?.error?.message}
                        value={field.value?.value}
                        label={field.value?.label}
                        filter={{country: watched?.country?.value}}
                        onOptionChange={field.onChange}
                    />
                )}
                rules={{
                    validate: {
                        required: value => {
                            const isRequired = isStateRequired(watched?.country?.value);
                            const isValueExist = !!value?.value?.length;
                            return isRequired && !isValueExist ? validationFormatter(RuleType.Required, localized.state) : null;
                        },
                    },
                }}
                control={control}
            />
        </div>
    ) : (
        <EditableTextInternal mode={mode} value={watched.licenseType} />
    );
}

type UserLicenseTypeOuterProps = {
    userId?: string;
    licenseType?: string;
    country: {
        iso2Code: string;
        name: string;
    };
    state: string;
};

const UserLicenseTypeWithEditButton = withEditButton(UserProfileLicenseTypeInternal);

function UserLicenseTypeOuter(props: UserLicenseTypeOuterProps) {
    const {country, licenseType, state, userId} = props;
    const defaultValue: UserProfileLicenseTypeFormModel = {
        country: {
            value: country?.iso2Code,
            label: country?.name,
        },
        state: {
            value: state,
            label: state,
        },
        licenseType,
    };
    const {control, resetField, cancel, submit, handleSubmit} = useReduxForm<
        UserProfileLicenseTypeFormModel,
        EditLicenseTypeRequestPayload
    >({
        initialModel: defaultValue,
        asyncAction: personalInfoActions.editLicenseType,
        map: m => ({
            uid: userId,
            country: {
                iso_alpha2_code: m.country?.value,
                name: m.country?.label,
            },
            state: m.state?.value,
            license_type: m.licenseType,
        }),
    });
    const {requestMode} = useReduxFormRequestMode<EditLicenseTypeRequestPayload>(personalInfoActions.editLicenseType);
    return (
        <form onSubmit={handleSubmit(submit)}>
            <UserLicenseTypeWithEditButton
                {...props}
                mode={requestMode}
                onSave={() => handleSubmit(submit)}
                onCancel={cancel}
                control={control}
                resetField={resetField}
            />
        </form>
    );
}

export const UserProfileLicenseType = withPolicyAccessCheck(
    UserLicenseTypeOuter,
    userProfileLicenseTypeEditResourse,
    AccessControlIndicator.Disabled
);
