import React, {useRef} from 'react';
import {defineMessages} from 'react-intl';

import {DataGridColumn, DataGridServer, DataGridServerProps, TextColumn} from '@components/data-grid';
import {ModalButtonColumn, ModalButtonColumnValue} from '@components/data-grid/types';
import {ModalContent} from '@components/modal';
import {formatDate} from '@utils';

import LocalizedText from 'src/common/components/i18n/LocalizedText';
import {LegalDocHistoryViewModel, LegalDocHistoryViewModelKeys} from '../types';

import {localizedLegalDocHistoryDataGrid} from './DataGridLegalDocHistory.localize';
import {VersionHistoryDetailsModal} from './VersionHistoryDetailsModal';

const localized = defineMessages({
    viewDocModalTitle: {
        id: 'DataGridLegalDocHistory_viewDocModalTitle',
        defaultMessage: 'Version History',
    },
    viewDocModalButtonLabel: {
        id: 'DataGridLegalDocHistory_viewDocModalButtonLabel',
        defaultMessage: 'View',
    },
});

export type LegalDocHistoryGridViewModel = LegalDocHistoryViewModel;
export type LegalDocHistoryGridViewModelKeys = LegalDocHistoryViewModelKeys | 'view_document';

class DataGridLegalDocHistoryConfigFactory {
    private readonly _config: Partial<Record<LegalDocHistoryGridViewModelKeys, DataGridColumn<LegalDocHistoryGridViewModel>>>;

    constructor() {
        const license_type: TextColumn<LegalDocHistoryGridViewModel> = {
            field: 'license_type',
            valueGetter: model => model?.license_type,
            title: localizedLegalDocHistoryDataGrid.license_type,
            sortable: true,
            width: 200,
            flex: 0.2,
            type: 'text',
        };
        const version: TextColumn<LegalDocHistoryGridViewModel> = {
            field: 'version',
            valueGetter: model => this.getNumberValue(model?.version),
            title: localizedLegalDocHistoryDataGrid.version,
            sortable: true,
            width: 200,
            flex: 0.2,
            type: 'text',
        };
        const created_at: TextColumn<LegalDocHistoryGridViewModel> = {
            field: 'created_at',
            valueGetter: model => this.getDateValue(model?.created_at),
            title: localizedLegalDocHistoryDataGrid.created_at,
            sortable: true,
            width: 250,
            flex: 0.25,
            type: 'text',
        };
        const user_id: TextColumn<LegalDocHistoryGridViewModel> = {
            field: 'user_id',
            valueGetter: model => model?.user_id,
            title: localizedLegalDocHistoryDataGrid.user_id,
            sortable: true,
            width: 300,
            flex: 0.3,
            type: 'text',
        };
        const user_email: TextColumn<LegalDocHistoryGridViewModel> = {
            field: 'user_email',
            valueGetter: model => model?.user_email,
            title: localizedLegalDocHistoryDataGrid.user_email,
            sortable: false,
            width: 300,
            flex: 0.3,
            type: 'text',
        };
        const ip: TextColumn<LegalDocHistoryGridViewModel> = {
            field: 'ip',
            valueGetter: model => model?.ip,
            title: localizedLegalDocHistoryDataGrid.ip,
            sortable: true,
            width: 200,
            flex: 0.2,
            type: 'text',
        };
        const viewDocumentAction: ModalButtonColumn<LegalDocHistoryGridViewModel> = {
            field: 'view_document',
            valueGetter: this.getViewDocumentVersionCellValue,
            sortable: false,
            width: 200,
            flex: 0.2,
            type: 'modal_button',
        };

        this._config = {
            license_type: license_type,
            version: version,
            created_at: created_at,
            user_id: user_id,
            user_email: user_email,
            ip: ip,
            view_document: viewDocumentAction,
        };
    }

    public get config(): Partial<Record<LegalDocHistoryGridViewModelKeys, DataGridColumn<LegalDocHistoryGridViewModel>>> {
        return this._config;
    }

    private getNumberValue(value: number): string {
        return value?.toString();
    }

    private getDateValue(date: Date) {
        return formatDate(date, 'date-time');
    }

    private getViewDocumentVersionCellValue(model: LegalDocHistoryGridViewModel): ModalButtonColumnValue {
        const modalContent: ModalContent = {
            title: <LocalizedText label={localized.viewDocModalTitle} />,
            body: <VersionHistoryDetailsModal documentType={model?.type} licenseType={model?.license_type} version={model?.version} />,
            styleParams: {
                maxWidth: 'md',
            },
        };
        return {label: localized.viewDocModalButtonLabel, modalContent};
    }
}

type DataGridLegalDocHistoryProps = Omit<
    DataGridServerProps<LegalDocHistoryGridViewModel, LegalDocHistoryGridViewModelKeys>,
    'type' | 'columns'
> & {
    columns: LegalDocHistoryGridViewModelKeys[];
};

export function DataGridLegalDocHistory(props: DataGridLegalDocHistoryProps) {
    const columns = useRef(getColumns(new DataGridLegalDocHistoryConfigFactory().config));

    function getColumns(
        config: Partial<Record<LegalDocHistoryGridViewModelKeys, DataGridColumn<LegalDocHistoryGridViewModel>>>
    ): DataGridColumn<LegalDocHistoryGridViewModel>[] {
        return props?.columns?.map((column: LegalDocHistoryGridViewModelKeys) => config[column]);
    }

    return <DataGridServer {...props} type="mui" columns={columns.current} />;
}
